import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Button } from "../components/Button";
import TransactionCategoryModal from "../components/modals/TransactionCategoryModal";
import BaseTable from "../components/monthly-budget/Table";
import { useTransactionCategories } from "../hooks/useTransactionCategories";
import { categoriesColumns } from "./shared/CategoriesTable";

export default function CategoriesPage() {

    const [open, setOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    const threeDotsItems = [
        {
            name: 'Adicionar categoria', description: 'Adicione uma nova categoria', onClick: () => {
                setSelectedCategory(null)
                setOpen(true);
            }, icon: PlusCircleIcon
        },
    ]

    return <div className="flex flex-col flex-grow w-full gap-2">
        <TransactionCategoryModal open={open} onClose={() => {
            setOpen(false);
            setSelectedCategory(null);
        }} categoryId={selectedCategory} />
        <div className="flex flex-row w-full justify-between items-center">
            <h1 className="text-primary font-primary">Categorias</h1>
            <Button
                className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white"
                onClick={() => {
                    setSelectedCategory(null)
                    setOpen(true);
                }}
                title="+ Nova Categoria" />
        </div>
        <BaseTable
            className="rounded-md"
            title={null}
            onRowClick={(row) => {
                setSelectedCategory(row.id);
                setOpen(true);
            }}
            columns={categoriesColumns}
            useGetData={useTransactionCategories}
        />
    </div>
    // <Card>
    //     <TitleCard
    //         actions={threeDotsItems}
    //         boxClassName="p-0 border-none flex-col" title={`Categorias`} subtitle="Gerencie suas categorias de transações"
    //         titleBoxClassName="md:flex-row md:items-center">
    //     </TitleCard>
    // </Card>
}
