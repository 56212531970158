import queryClient, { QueryKey } from "../hooks/state";
import { MonthBudget, MonthBudgetFetchParams, MonthBudgetSummary, MonthBudgetSummaryFetchParams, PaginatedResponse } from "../models/types";
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/month_budget",
  retrieve: (id: string) => `/central/central/month_budget/${id}`,
  summary: () => "/central/central/month_budget/summary",
  copy: () => "/central/central/month_budget/copy",
};

export const getMonthBudget = async (objectId: string) => {
  return httpGetAuthenticated<MonthBudget>(endpoints.retrieve(objectId));
}

export const getMonthBudgetSummary = async (params: MonthBudgetSummaryFetchParams) => {
  return httpGetAuthenticated<MonthBudgetSummary>(endpoints.summary(), {
    params,
  });
}

export const getMonthBudgets = async (params: MonthBudgetFetchParams) => {
  return httpGetAuthenticated<PaginatedResponse<MonthBudget>>(endpoints.list(), {
    params,
  });
}

export const getMonthsWithBudget = async () => {
  return httpGetAuthenticated<{months: {month: number, year: number}[]}>(endpoints.list() + "/months");
}

export const createMonthBudget = async (payload: {
  year: number;
  month: number;
  category: string;
  budget_amount: number;
}) => {
  const response = await httpPostAuthenticated<MonthBudget>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.monthBudget);
  queryClient.invalidateQueries([QueryKey.monthBudgetSummary]);
  return response.data;
}

export const updateMonthBudget = async (id: string, payload: {
  year?: number;
  month?: number;
  category?: string;
  budget_amount: number;
}) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.monthBudget);
  return response.data;
}

export const deleteMonthBudget = async (id: string) => {
  await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.monthBudget);
}

export const copyMonthBudget = async (payload: {
  month: number;
  year: number;
  copy_month: number;
  copy_year: number;
}) => {
  const response = await httpPostAuthenticated(endpoints.copy(), payload);
  queryClient.invalidateQueries(QueryKey.monthBudget);
  queryClient.invalidateQueries([QueryKey.monthBudgetSummary]);
  return response.data;
}