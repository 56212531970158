import { TagIcon } from "@heroicons/react/24/outline";
import { format, parse } from "date-fns";
import { useMonthBudgets } from "../hooks/useMonthBudget";
import { MonthBudget, MonthSummaryData } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";
import CourseProgress from "./CourseProgress";
import { BudgetEmptyState } from "./EmptyTable";
import GroupedBudgetSkeletonLoader from "./skeleton/GroupedBudgetSkeletonLoader";

interface StackedSummaryProps {
    month?: string;
}

export default function StackedSummary({ month }: StackedSummaryProps) {
    // Use provided month or fallback to current date
    const currentMonth = month || format(new Date(), "yyyy-MM-dd");

    // Parse the month string to get year and month values for API call
    const parsedDate = parse(currentMonth, "yyyy-MM-dd", new Date());
    const monthNumber = parsedDate.getMonth() + 1; // JavaScript months are 0-indexed, but our API expects 1-indexed
    const year = parsedDate.getFullYear();

    // Fetch month budgets with expenses included
    const { data: monthBudgets, isLoading } = useMonthBudgets({
        month: monthNumber,
        year: year,
        page_size: 50,
        include_expenses: true
    });

    // Transform the data to match the expected format for CategorySummary
    const summaryData: MonthSummaryData[] = monthBudgets?.results?.map((budget: MonthBudget) => ({
        id: budget.category,
        name: budget.category_name?.name || "Sem categoria",
        total_amount: budget.current_expense || 0,
        budget_amount: parseFloat(budget.budget_amount)
    })) || [];

    // Show empty state when no budget data is available
    if (summaryData.length === 0 && !isLoading) {
        return <BudgetEmptyState />;
    }

    return (
        <div className="flex flex-col w-full bg-primaryBg border border-secondaryBorder rounded-xl flex-grow px-2 py-1 shadow-lg overflow-hidden">
            {isLoading && <GroupedBudgetSkeletonLoader />}
            {!isLoading && summaryData.map((value, index) => (
                <CategorySummary
                    key={index}
                    value={value}
                    isLast={index === summaryData.length - 1}
                />
            ))}
        </div>
    );
}

export function CategorySummary({ value, isLast }: { value: MonthSummaryData, isLast: boolean }) {
    const available = (value.budget_amount - value.total_amount) < 0 ? 0 : (value.budget_amount - value.total_amount);
    return <div className={`flex flex-col md:flex-row w-full gap-3 md:gap-0 py-1 items-start md:items-center justify-between ${isLast ? "" : "border-b border-gray-200"}`}>
        <div className="flex flex-row gap-2 items-center">
            <div className="rounded-full h-7 w-7 bg-gray-200 flex items-center justify-center">
                <TagIcon className="h-4 w-4 text-gray-500" />
            </div>
            <div className="flex flex-col">
                <h3 className="text-sm font-text text-gray-400">{value.name}</h3>
                <h3 className="text-xs font-text text-primaryText">Restam R$ {formatBrazilianCurrence((available).toFixed(2))}</h3>
            </div>
        </div>
        <div className="flex flex-col justify-center items-end gap-1 min-w-full md:min-w-48">
            <CourseProgress hideText progress={{
                completed: value.total_amount,
                total: value.budget_amount,
                is_completed: available === 0,
            }} />
            <h3 className="text-xs font-text text-primaryText"> R$ {formatBrazilianCurrence((value.total_amount).toFixed(2))} de R$ {formatBrazilianCurrence((value.budget_amount).toFixed(2))}</h3>
        </div>
    </div>
}