import CourseCard from "../components/CourseCard";
import { useCourses } from "../hooks/useCourses";

export default function CoursesPage() {
    const { data: courses, isLoading } = useCourses({});
    return <><h1 className="text-primary font-primary capitalize mb-2">Cursos</h1>
        {isLoading && <CourseSkeletonLoader />}
        {!isLoading && <div className="mx-auto justify-center md:justify-start flex md:flex-row gap-4 flex-wrap">
            {courses?.results?.map((course) => <CourseCard key={course.id} course={course} />)}
        </div>}
    </>
}

function CourseSkeletonLoader() {
    return <div className="flex flex-row gap-4 flex-wrap">
        <CourseCardLoader />
        <CourseCardLoader />
        <CourseCardLoader />
        <CourseCardLoader />
        <CourseCardLoader />
        <CourseCardLoader />
    </div>
}

function CourseCardLoader() {
    return <div className="flex flex-col gap-2 w-60">
        <div
            className={`min-h-96 cursor-pointer h-96 flex w-60 justify-center shadow-lg rounded-lg bg-cover bg-primaryBg border-secondaryBorder border group animate-pulse`}>
        </div>
        <div className="flex w-full h-2 rounded-full bg-primaryBg animate-pulse"></div>
    </div>
}