import { useState, useEffect } from 'react';
import Card from '../Card';
import MainInput from '../MainInput';
import { Button } from '../Button';
import { FinancialIndependenceData, FinancialIndependenceResult } from '../../models/types';
import { ResultadoIndependenciaFinanceira } from './ResultadoIndependenciaFinanceira';
import { GraficoIndependenciaFinanceira } from './GraficoIndependenciaFinanceira';

type CalculadoraIndependenciaFinanceiraProps = {
  data: FinancialIndependenceData | null;
  setData: (data: FinancialIndependenceData) => void;
}

export const CalculadoraIndependenciaFinanceira = ({ data, setData }: CalculadoraIndependenciaFinanceiraProps) => {
  // Estado para armazenar os valores do formulário
  const [patrimonio, setPatrimonio] = useState<string>("0");
  const [aporte, setAporte] = useState<string>("1000");
  const [retorno, setRetorno] = useState<string>("10");
  const [valorMeta, setValorMeta] = useState<string>("5000");
  const taxaSaque = "4"; // Valor fixo para taxa de saque
  
  // Estado para armazenar erros de validação
  const [errors, setErrors] = useState({
    patrimonio: "",
    aporte: "",
    retorno: "",
    valorMeta: ""
  });

  // Estado para armazenar o resultado do cálculo
  const [resultado, setResultado] = useState<FinancialIndependenceResult | null>(null);

  // Função para validar o formulário
  const validateForm = (): boolean => {
    const newErrors = {
      patrimonio: "",
      aporte: "",
      retorno: "",
      valorMeta: ""
    };
    
    let isValid = true;

    // Validar patrimônio
    if (parseFloat(patrimonio) < 0) {
      newErrors.patrimonio = "O patrimônio não pode ser negativo";
      isValid = false;
    }

    // Validar aporte mensal
    if (parseFloat(aporte) <= 0) {
      newErrors.aporte = "O aporte mensal deve ser maior que zero";
      isValid = false;
    }

    // Validar taxa de juros anual
    if (parseFloat(retorno) <= 0) {
      newErrors.retorno = "A taxa de juros anual deve ser maior que zero";
      isValid = false;
    }

    // Validar valor meta mensal
    if (parseFloat(valorMeta) <= 0) {
      newErrors.valorMeta = "O valor meta mensal deve ser maior que zero";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Função para formatar valores monetários
  const formatCurrency = (value: string): string => {
    // Remove qualquer caractere não numérico, exceto ponto decimal
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    // Converte para número
    const number = parseFloat(numericValue);
    
    // Retorna string vazia se não for um número válido
    if (isNaN(number)) return '';
    
    return number.toString();
  };

  // Função para calcular a independência financeira
  const calcularIndependenciaFinanceira = () => {
    if (!validateForm()) return;

    const patrimonioInicial = parseFloat(patrimonio);
    const aporteMensal = parseFloat(aporte);
    const retornoAnual = parseFloat(retorno) / 100;
    const valorMetaMensal = parseFloat(valorMeta);
    const taxaSaqueAnual = parseFloat(taxaSaque) / 100;

    // Atualizar o estado de data
    setData({
      patrimonio_atual: patrimonioInicial,
      aporte_mensal: aporteMensal,
      retorno_anual: retornoAnual * 100,
      valor_meta_mensal: valorMetaMensal,
      taxa_saque_anual: taxaSaqueAnual * 100
    });

    // Calcular o patrimônio necessário para a independência financeira
    const patrimonioNecessario = (valorMetaMensal * 12) / taxaSaqueAnual;

    // Calcular quantos anos serão necessários para atingir o patrimônio
    let patrimonioAtual = patrimonioInicial;
    let anos = 0;
    let totalAportes = patrimonioInicial;
    let totalJuros = 0;
    const projecaoPatrimonio = [];

    // Retorno mensal equivalente ao retorno anual
    const retornoMensal = Math.pow(1 + retornoAnual, 1/12) - 1;

    while (patrimonioAtual < patrimonioNecessario) {
      // Adicionar um mês
      patrimonioAtual = patrimonioAtual * (1 + retornoMensal) + aporteMensal;
      
      // Se completou um ano
      if (anos % 12 === 0) {
        const anoAtual = Math.floor(anos / 12);
        const jurosAno = patrimonioAtual - totalAportes;
        
        projecaoPatrimonio.push({
          ano: anoAtual,
          patrimonio: patrimonioAtual,
          aporte_acumulado: totalAportes,
          juros_acumulados: jurosAno
        });
      }
      
      totalAportes += aporteMensal;
      anos++;
      
      // Limite de 100 anos para evitar loop infinito
      if (anos > 1200) break;
    }

    totalJuros = patrimonioAtual - totalAportes;

    // Calcular o resultado
    const result: FinancialIndependenceResult = {
      anos_para_independencia: Math.ceil(anos / 12),
      patrimonio_total: patrimonioAtual,
      total_aportes: totalAportes,
      total_juros: totalJuros,
      projecao_patrimonio: projecaoPatrimonio
    };

    setResultado(result);
  };

  // Resetar o formulário quando a página for carregada
  useEffect(() => {
    setPatrimonio("0");
    setAporte("1000");
    setRetorno("10");
    setValorMeta("5000");
    setResultado(null);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      {/* Primeira linha: Parâmetros e Resultado lado a lado */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          <h2 className="text-lg font-semibold text-gray-900">Parâmetros da Simulação</h2>
          <p className="text-sm text-gray-600">
            Preencha os dados abaixo para calcular quanto tempo você precisa para alcançar sua independência financeira.
          </p>
          
          <div className="flex flex-col gap-4 mt-2">
            <MainInput
              title="Patrimônio Atual (R$)"
              type="text"
              placeholder="0"
              value={patrimonio}
              onChange={(e) => setPatrimonio(formatCurrency(e.target.value))}
              error={errors.patrimonio}
            />
            
            <MainInput
              title="Aporte Mensal (R$)"
              type="text"
              placeholder="1000"
              value={aporte}
              onChange={(e) => setAporte(formatCurrency(e.target.value))}
              error={errors.aporte}
            />
            
            <MainInput
              title="Taxa de Juros Anual (%)"
              type="text"
              placeholder="10"
              value={retorno}
              onChange={(e) => setRetorno(formatCurrency(e.target.value))}
              error={errors.retorno}
            />
            
            <MainInput
              title="Valor Meta Mensal (R$)"
              type="text"
              placeholder="5000"
              value={valorMeta}
              onChange={(e) => setValorMeta(formatCurrency(e.target.value))}
              error={errors.valorMeta}
            />
            
            <Button 
              title="Calcular"
              onClick={calcularIndependenciaFinanceira}
              className="mt-4 w-full"
            />
          </div>
        </Card>
        
        {resultado && <ResultadoIndependenciaFinanceira resultado={resultado} />}
      </div>
      
      {/* Segunda linha: Gráfico apenas, já que só temos um */}
      {resultado && (
        <div>
          <GraficoIndependenciaFinanceira resultado={resultado} />
        </div>
      )}
    </div>
  );
};