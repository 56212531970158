import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../components/Button";
import { resetPassword } from "../services/LoginService";
import { setCookie } from "../utils/cookies";

export function ResetPassword() {
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [token, setToken] = useState<string>("");
    
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Get token from URL query parameter
        const params = new URLSearchParams(location.search);
        const tokenParam = params.get("token");
        
        if (!tokenParam) {
            setError("Token de redefinição de senha não encontrado. Por favor, solicite um novo link de redefinição.");
            return;
        }
        
        setToken(tokenParam);
    }, [location]);

    const validatePassword = (password: string): boolean => {
        // At least 8 characters, with letters and numbers
        const regex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
        return regex.test(password);
    };

    const handleResetPassword = async () => {
        // Reset error state
        setError("");
        
        // Validate password
        if (!validatePassword(password)) {
            setError("A senha deve ter pelo menos 8 caracteres e incluir letras e números.");
            return;
        }
        
        // Check if passwords match
        if (password !== confirmPassword) {
            setError("As senhas não coincidem.");
            return;
        }
        
        setLoading(true);
        
        try {
            const response = await resetPassword(token, password);
            
            if (response.status === 200) {
                // Set auth tokens
                setCookie("token", response.data.access);
                setCookie("refresh", response.data.refresh);
                
                // Show success message
                setSuccess(true);
                
                // Redirect to home page after 2 seconds
                setTimeout(() => {
                    navigate("/inicio");
                }, 2000);
            } else {
                setError("Não foi possível redefinir sua senha. O token pode ter expirado.");
            }
        } catch (e) {
            setError("Não foi possível redefinir sua senha. O token pode ter expirado.");
        } finally {
            setLoading(false);
        }
    };

    if (!token) {
        return (
            <div className="flex min-h-screen w-full items-center flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 gap-4">
                <img src="/images/logo.png" alt="Logo" className="h-20 w-auto cursor-pointer" onClick={() => navigate("/home")} />
                <div className="border-primaryBorder border bg-primaryBg rounded-2xl p-4 max-w-xs sm:max-w-sm flex w-full flex-col items-center gap-4">
                    <div className="text-center">
                        <h2 className="text-primary font-primary mb-4">Redefinição de senha</h2>
                        <p className="text-sm text-red-600 mb-4">{error}</p>
                        <Button
                            onClick={() => navigate("/login")}
                            className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                            title="Voltar ao login"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex min-h-screen w-full items-center flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 gap-4">
            <img src="/images/logo.png" alt="Logo" className="h-20 w-auto cursor-pointer" onClick={() => navigate("/home")} />
            <div className="border-primaryBorder border bg-primaryBg rounded-2xl p-4 max-w-xs sm:max-w-sm flex w-full flex-col items-center gap-4">
                <h2 className="text-primary font-primary">Redefinir sua senha</h2>
                
                {success ? (
                    <div className="text-center">
                        <p className="text-sm text-green-600 mb-2">
                            Sua senha foi redefinida com sucesso!
                        </p>
                        <p className="text-sm text-gray-700">
                            Você será redirecionado para a página inicial automaticamente.
                        </p>
                    </div>
                ) : (
                    <>
                        <p className="text-sm text-gray-700 mb-4">
                            Crie uma nova senha para sua conta. A senha deve ter pelo menos 8 caracteres e incluir letras e números.
                        </p>
                        
                        <div className="flex flex-row w-full items-center gap-1">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                                required
                                placeholder="Nova senha"
                                className="py-2 px-4 w-full border-secondaryBorder rounded-2xl text-xs cursor-pointer"
                            />
                            {!showPassword && <EyeIcon onClick={() => setShowPassword(true)} className="h-5 w-5 text-gray-700 cursor-pointer" />}
                            {showPassword && <EyeSlashIcon onClick={() => setShowPassword(false)} className="h-5 w-5 text-gray-700 cursor-pointer" />}
                        </div>
                        
                        <div className="flex flex-row w-full items-center gap-1">
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"
                                required
                                placeholder="Confirmar nova senha"
                                className="py-2 px-4 w-full border-secondaryBorder rounded-2xl text-xs cursor-pointer"
                            />
                            {!showConfirmPassword && <EyeIcon onClick={() => setShowConfirmPassword(true)} className="h-5 w-5 text-gray-700 cursor-pointer" />}
                            {showConfirmPassword && <EyeSlashIcon onClick={() => setShowConfirmPassword(false)} className="h-5 w-5 text-gray-700 cursor-pointer" />}
                        </div>
                        
                        <Button
                            disabled={loading}
                            loading={loading}
                            onClick={handleResetPassword}
                            className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                            title="Redefinir senha"
                        />
                    </>
                )}
                
                {error && <h4 className="px-2 py-2 text-xs font-light text-red-600">{error}</h4>}
            </div>
        </div>
    );
}