import { format, parseISO } from "date-fns";
import { BaseBadge } from "../../components/Badge";
import CustomSwitch from "../../components/Switch";
import { MonthlyTransaction, TableColumn } from "../../models/types";
import { updateMonthlyTransaction } from "../../services/MonthlyTransactionService";
import { formatBrazilianCurrence } from "../../utils/helper";

export const transactionColumns: TableColumn<MonthlyTransaction>[] = [
    {
        field: "paid",
        header: "Pago",
        render: (t) => <div onClick={async (e) => {
            e.stopPropagation();
            await updateMonthlyTransaction(t.id, { paid: !t.paid });
        }}><CustomSwitch visible={t.paid} setVisible={() => { }} label="" /></div>,
        className: "w-16 max-w-16 md:max-w-16  overflow-hidden truncate",
    },
    {
        field: "name",
        header: "Nome",
        render: (t) => <h4 className="text-xs md:text-sm text-gray-900 font-light max-w-full truncate">{t.name}</h4>,
        className: "min-w-40 max-w-20 truncate",
    },
    {
        field: "category_name",
        header: "Categoria",
        className: "hidden md:flex",
        render: (t) =>
            <BaseBadge title={t?.category_name?.name ?? "Sem categoria"} />,
    },
    {
        field: "month",
        header: "Vencimento",
        className: "min-w-40 max-w-20 truncate",
        render: (t) =>
            <h4 className="text-xs md:text-sm font-light text-gray-900 truncate">{format(parseISO(t.month), "dd/MM/yyyy")}</h4>
    },
    {
        field: "amount",
        header: "Valor",
        render: (t) =>
            <h4 className={`text-sm font-semibold ${t.type === "credit" ? "text-green-600" : "text-red-600"}`}>R$ {formatBrazilianCurrence(t.amount)}</h4>,
    },
]