import { CheckCircleIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { config } from "../../config/env";
import { useAppContext } from "../../context/AppContext";
import { useUser } from "../../hooks/useUsers";
import { Button } from "../Button";
import BaseModal from "./BaseModal";

type PaymentModalProps = {
    // Empty type for future extensibility
}

export default function PaymentModal({ }: PaymentModalProps) {
    const { paymentModal, setPaymentModal } = useAppContext();
    const { data: user } = useUser();
    const isOsd = user?.plan && user?.plan === "OSD";
    
    return (
        <BaseModal open={paymentModal} onClose={() => setPaymentModal(false)}>
            <div className="flex w-full flex-col">
                {isOsd ? (
                    <div className="text-center">
                        <div className="flex justify-center mb-4">
                            <SparklesIcon className="h-12 w-12 text-blue-600" />
                        </div>
                        <h3 className="text-primary font-primary text-center mb-2">Desbloqueie benefícios exclusivos</h3>
                        <p className="text-text font-text text-thirdText mb-6">
                            O recurso que você está tentando acessar não está incluso em sua assinatura. 
                            Entre em contato com nosso time para consultar como alterar seu plano.
                        </p>
                        <Button 
                            className="w-full py-3 bg-blue-600 hover:bg-blue-700 mb-2" 
                            onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1,%20gostaria%20de%20alterar%20meu%20plano", "_blank")} 
                            title="Fale conosco no WhatsApp" 
                        />
                    </div>
                ) : (
                    <div>
                        <div className="flex justify-center mb-4">
                            <div className="h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center">
                                <SparklesIcon className="h-10 w-10 text-blue-600" />
                            </div>
                        </div>
                        <h3 className="text-big font-primary text-primaryText text-center mb-6">
                            Torne-se um membro e desbloqueie<br />benefícios exclusivos
                        </h3>
                        
                        <div className="bg-blue-50 rounded-xl p-4 mb-6">
                            <div className="space-y-3">
                                {[
                                    "Ferramenta para controle e acompanhamento de metas",
                                    "Ferramenta para montar orçamento mensal",
                                    "Ferramenta para anotar todas suas despesas",
                                    "Trilha para utilização da plataforma",
                                    "Materiais bônus",
                                    "Suporte via WhatsApp",
                                    "1 ano de acesso"
                                ].map((feature, index) => (
                                    <div key={index} className="flex items-center gap-3">
                                        <CheckCircleIcon className="h-5 w-5 text-blue-600 flex-shrink-0" />
                                        <p className="text-text font-text text-thirdText">{feature}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        <div className="text-center mb-6">
                            <p className="text-text font-text text-thirdText mb-2">Tudo isso por apenas</p>
                            <div className="flex items-center justify-center gap-3">
                                <p className="text-big font-primary text-blue-600">R$97</p>
                                <span className="text-gray-400">ou</span>
                                <p className="text-accent font-accent text-blue-600">12x de R$9,74</p>
                            </div>
                        </div>
                        
                        <Button 
                            className="w-full py-3 bg-blue-600 hover:bg-blue-700 shadow-lg transform transition hover:scale-105 mb-2" 
                            onClick={() => window.open(`${config.payment.kiwify_checkout_url}?email=${user?.email}&name=${user?.first_name + " " + user?.last_name}`, "_blank")} 
                            title="Adquirir agora" 
                        />
                    </div>
                )}
                
                <Button 
                    className="mt-2 text-xs font-text text-gray-500 bg-transparent hover:bg-gray-50 hover:text-gray-700" 
                    title="Fechar" 
                    onClick={() => setPaymentModal(false)} 
                />
            </div>
        </BaseModal>
    );
}
