import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { differenceInMonths, format, parseISO } from "date-fns";
import { Goal } from "../models/types";
import { formatToBrazilianCurrency } from "../utils/handleMoneyChange";
import BaseCard from "./BaseCard";
import CourseProgress from "./CourseProgress";

export default function GoalCard({ goal, onEdit }: { goal: Goal, onEdit: (goalId: string) => void }) {
    const calculatePendingValuePerMonth = (goal: Goal) => {
        const goalDate = parseISO(goal.goal_date);
        const currentDate = new Date();
        const months = differenceInMonths(goalDate, currentDate);
        const pendingValue = goal.goal_amount - goal.amount;

        // Return 0 if:
        // - Goal is already achieved (pendingValue <= 0)
        // - Goal date is today or in the past (months <= 0)
        if (pendingValue <= 0 || months <= 0) return "0";

        // Calculate monthly savings amount
        const result = (pendingValue / months).toFixed(2);
        return result;
    }

    const handleToolTipLabel = (goal: Goal) => {
        // Check if goal is already achieved

        if (goal.progress.is_completed) {
            return "Parabéns, você atingiu sua meta!";
        }

        const goalDate = parseISO(goal.goal_date);
        const currentDate = new Date();
        const months = differenceInMonths(goalDate, currentDate);

        // Handle case when goal date is today or in the past
        if (months <= 0) {
            return "Este é o mês para atingir sua meta, não desista!";
        }

        const pendingValue = calculatePendingValuePerMonth(goal);
        return `Guarde R$${formatToBrazilianCurrency(pendingValue)}/mês para atingir`;
    }
    return (
        <BaseCard
            className="max-w-full md:max-w-80 min-w-72 shadow-lg hover:shadow-xl transition-shadow"
            icon={<PresentationChartBarIcon className="h-6 w-6 min-h-6 min-w-6 text-blue-600" />}
            title={goal.name}
            content={
                <div className="flex flex-col flex-grow px-2">
                    <div className="border-b border-primaryBorder mb-1 flex flex-row items-center justify-between truncate">
                        <span className="text-sm leading-7 font-text text-primaryText">Data</span>
                        <span className="text-sm leading-7 font-text text-primaryText">{format(parseISO(goal.goal_date), "dd/MM/yyyy")}</span>
                    </div>
                    <div className="border-b border-primaryBorder mb-1 flex flex-row items-center justify-between truncate">
                        <span className="text-sm leading-7 font-text text-primaryText">Meta</span>
                        <span className="text-sm leading-7 font-text text-primaryText">R$ {formatToBrazilianCurrency(`${goal.goal_amount}`)}</span>
                    </div>
                    <div className="border-b border-primaryBorder mb-1 flex flex-row items-center justify-between truncate">
                        <span className="text-sm leading-7 font-text text-primaryText">Acumulado</span>
                        <div className="flex flex-row items-center gap-2">
                            <span className="text-sm leading-7 font-text text-green-700">R$ {formatToBrazilianCurrency(`${goal.amount}`)}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 my-2">
                        <CourseProgress progress={{
                            completed: goal.amount,
                            total: goal.goal_amount,
                            is_completed: goal.progress.is_completed,
                        }} />
                        <h4 className="text-xs font-text text-secondaryText">
                            {handleToolTipLabel(goal)}
                        </h4>
                    </div>
                </div>
            }
            onClick={() => {
                onEdit(goal.id);
            }}
        />
    )
}