import React from 'react';
import Chart from 'react-apexcharts';
import Card from '../Card';
import { JurosCompostosResult } from './ResultadoJurosCompostos';

type GraficoEvolucaoJurosCompostosProps = {
  resultado: JurosCompostosResult;
}

export const GraficoEvolucaoJurosCompostos = ({ resultado }: GraficoEvolucaoJurosCompostosProps) => {
  // Preparar dados para o gráfico
  const anos = resultado.projecao.map(item => `Ano ${item.ano}`);
  const aportes = resultado.projecao.map(item => Math.round(item.aportes_acumulados));
  const juros = resultado.projecao.map(item => Math.round(item.juros_acumulados));
  const montantes = resultado.projecao.map(item => Math.round(item.capital_acumulado));

  // Opções do gráfico
  const options: any = {
    chart: {
      type: 'area',
      height: 350,
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: ['#4F46E5', '#10B981', '#F59E0B'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: [3, 3, 3]
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      categories: anos
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}M`;
          } else if (value >= 1000) {
            return `${(value / 1000).toFixed(0)}K`;
          }
          return value.toFixed(0);
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value);
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right'
    }
  };

  // Séries de dados
  const series = [
    {
      name: 'Montante Total',
      data: montantes
    },
    {
      name: 'Aportes',
      data: aportes
    },
    {
      name: 'Juros',
      data: juros
    }
  ];

  return (
    <Card>
      <h2 className="text-lg font-semibold text-gray-900 mb-2">Evolução do Patrimônio</h2>
      <div className="h-80">
        <Chart
          options={options}
          series={series}
          type="area"
          height="100%"
        />
      </div>
    </Card>
  );
};