import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useAppContext } from "../context/AppContext";
import { MonthSummaryData } from "../models/types";
import { Button } from "./Button";
import { CategorySummary } from "./StackedSummary";

export default function StackedSummaryPaywall() {
    const { setPaymentModal } = useAppContext();
    const groupedSummary: MonthSummaryData[] = [
        {
            id: "0",
            name: "Custos Fixos",
            budget_amount: 3500,
            total_amount: 3240,
        },
        {
            id: "1",
            name: "Custos Variáveis",
            budget_amount: 1500,
            total_amount: 800,
        },
        {
            id: "2",
            name: "Investimentos",
            budget_amount: 2000,
            total_amount: 2000,
        },
        {
            id: "3",
            name: "Metas",
            budget_amount: 1500,
            total_amount: 1500,
        },
        {
            id: "4",
            name: "Lazer",
            budget_amount: 1000,
            total_amount: 500,
        },
        {
            id: "2",
            name: "Estudos",
            budget_amount: 500,
            total_amount: 100,
        }
    ]
    return (
        <div className="flex flex-col w-full bg-primaryBg border border-secondaryBorder rounded-xl shadow-lg overflow-hidden relative">
            {/* Budget summary content with reduced opacity */}
            <div className="flex flex-col w-full p-2 opacity-20 pointer-events-none">
                {groupedSummary?.map((value, index) => (
                    <CategorySummary 
                        key={index} 
                        value={value} 
                        isLast={index === groupedSummary.length - 1} 
                    />
                ))}
            </div>
            
            {/* Custom centered paywall button that overlays the content */}
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                    <LockClosedIcon className="h-16 w-16 text-gray-500 mb-2" />
                    <Button 
                        title="Exclusivo para assinantes" 
                        onClick={() => setPaymentModal(true)} 
                    />
                </div>
            </div>
        </div>
    )
}