import { MonthBudget, TableColumn } from "../../models/types";
import { formatBrazilianCurrence } from "../../utils/helper";

export const monthBudgetColumns: TableColumn<MonthBudget>[] = [
    {
        field: "category_name",
        header: "Categoria",
        className: "max-w-20 truncate",
        render: (t) => <h4 className="text-sm truncate text-gray-500">{t.category_name.name}</h4>,
    },
    {
        field: "budget_amount",
        header: "Orçamento",
        className: "w-10",
        render: (t) => <h4 className="text-text font-text text-primaryText">R$ {formatBrazilianCurrence(t.budget_amount)}</h4>,
    },
    {
        field: "current_expense",
        header: "Gasto",
        className: "w-10",
        render: (t) => <h4 className="text-text font-text text-primaryText">R$ {formatBrazilianCurrence(t.current_expense ?? 0)}</h4>,
    },
    {
        field: "current_expense",
        header: "Restante",
        className: "w-10",
        render: (t) => {
            const amountLeft = Number(t.budget_amount) - (t.current_expense ?? 0);
            return <h4 className={`text-text font-text ${amountLeft < 0 ? "text-red-600" : "text-primaryText"}`}>R$ {formatBrazilianCurrence(amountLeft)}</h4>
        },
    },

]