import { useEffect, useRef } from 'react';

type VideoProps = {
    videoId: string;
    title: string;
    onEnd: () => void;
}

export default function Video({ videoId, title, onEnd }: VideoProps) {
    // Use a ref to track if the event has been triggered to prevent multiple calls
    const hasTriggeredEndRef = useRef<boolean>(false);
    
    useEffect(() => {
        // Reset the trigger flag when videoId changes
        hasTriggeredEndRef.current = false;
        
        // Handler function that ensures we only call onEnd once
        const handleMessage = (event: MessageEvent) => {
            const { data } = event;
            if (data.message === 'panda_ended' && !hasTriggeredEndRef.current) {
                // Set the flag to prevent multiple triggers
                hasTriggeredEndRef.current = true;
                onEnd();
            }
        };
        
        // Add the event listener
        window.addEventListener("message", handleMessage, false);
        
        // Clean up when the component unmounts or videoId changes
        return () => {
            window.removeEventListener("message", handleMessage, false);
        };
    }, [videoId, onEnd]); // Dependencies
    
    return (
        <div className="flex flex-grow">
            <iframe 
                id={`panda-${videoId}`}  
                src={`https://player-vz-377acd8c-682.tv.pandavideo.com.br/embed/?v=${videoId}`}
                title={title}
                allowFullScreen
                className="w-full aspect-video rounded-lg shadow-lg border-primaryBorder border"
            ></iframe>
        </div>
    );
}