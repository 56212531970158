import { useState, useEffect } from 'react';
import { CalculadoraIndependenciaFinanceira } from '../components/IndependenciaFinanceira/CalculadoraIndependenciaFinanceira';
import { FinancialIndependenceData } from '../models/types';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function IndependenciaFinanceiraPage() {
  // Estado para armazenar os dados da calculadora
  const [data, setData] = useState<FinancialIndependenceData | null>(null);
  const navigate = useNavigate();

  // Resetar dados quando a página for carregada
  useEffect(() => {
    setData(null);
  }, []);

  return (
    <div className="flex flex-col gap-3">
      {/* Botão de voltar */}
      <div className="mb-4">
        <button
          onClick={() => navigate('/simuladores')}
          className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors duration-200"
        >
          <ArrowLeftIcon className="h-4 w-4" />
          <span>Voltar</span>
        </button>
      </div>
      
      {/* Cabeçalho */}
      <div className="mb-2">
        <h1 className="text-xl font-semibold text-gray-900">Calculadora de Independência Financeira</h1>
        <p className="text-sm text-gray-600">
          Simule seu caminho para a independência financeira com base em seus aportes mensais, patrimônio atual e taxa de juros esperada.
        </p>
      </div>
      
      <CalculadoraIndependenciaFinanceira data={data} setData={setData} />
    </div>
  )
}