import Card from '../Card';
import { FinancialIndependenceResult } from '../../models/types';

type ResultadoIndependenciaFinanceiraProps = {
  resultado: FinancialIndependenceResult;
}

export const ResultadoIndependenciaFinanceira = ({ resultado }: ResultadoIndependenciaFinanceiraProps) => {
  // Função para formatar valores monetários
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  const stats = [
    { name: 'Anos para Independência', value: `${resultado.anos_para_independencia} anos` },
    { name: 'Patrimônio Final', value: formatCurrency(resultado.patrimonio_total) },
    { name: 'Total de Aportes', value: formatCurrency(resultado.total_aportes) },
    { name: 'Juros Acumulados', value: formatCurrency(resultado.total_juros) },
  ];

  return (
    <Card>
      <h2 className="text-lg font-semibold text-gray-900">Resultado da Simulação</h2>
      <p className="text-sm text-gray-600">
        Com base nos parâmetros informados, veja o resultado da sua simulação de independência financeira.
      </p>
      
      <div className="grid grid-cols-2 gap-4 mt-4 h-full">
        {stats.map((stat) => (
          <div key={stat.name} className="bg-gray-50 p-4 rounded-lg">
            <p className="text-sm font-medium text-gray-500">{stat.name}</p>
            <p className="mt-1 text-lg font-semibold text-gray-900">{stat.value}</p>
          </div>
        ))}
      </div>
    </Card>
  );
};