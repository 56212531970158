import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { TransactionCategory } from '../../models/types';

// Define Tailwind color palette
const COLOR_PALETTE = [
    '#10B981', // emerald-500
    '#3B82F6', // blue-500
    '#6366F1', // indigo-500
    '#8B5CF6', // violet-500
    '#EC4899', // pink-500
    '#F43F5E', // rose-500
    '#F59E0B', // amber-500
    '#84CC16', // lime-500
];

type BudgetChartProps = {
    categories: TransactionCategory[];
}

export const BudgetChart = ({ categories }: BudgetChartProps) => {
    const [labels, setLabels] = useState<string[]>([]);
    const [series, setSeries] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [legendPosition, setLegendPosition] = useState<'right' | 'bottom'>('right');

    const processData = () => {
        try {
            // Filter out categories with no budget amount
            const validCategories = categories.filter(c =>
                c.budget_amount !== null &&
                c.budget_amount !== undefined &&
                !isNaN(Number(c.budget_amount)) &&
                Number(c.budget_amount) > 0
            );

            // Extract labels and series data
            const categoryLabels = validCategories.map(c => c.name || "Sem categoria");
            const categorySeries = validCategories.map(c => Number(c.budget_amount) || 0);

            setLabels(categoryLabels);
            setSeries(categorySeries);
        } catch (error) {
            console.error("Error processing chart data:", error);
            setLabels([]);
            setSeries([]);
        } finally {
            setLoading(false);
        }
    };

    // Handle screen resizing to adjust legend position
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setLegendPosition('bottom');
            } else {
                setLegendPosition('right');
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        if (categories && categories.length > 0) {
            processData();
        } else {
            setLabels([]);
            setSeries([]);
            setLoading(false);
        }
    }, [categories]);

    if (loading) {
        return (
            <div className="flex items-center justify-center w-full h-64">
                <div className="animate-pulse w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
                    <p className="text-gray-500">Carregando dados...</p>
                </div>
            </div>
        );
    }

    if (labels.length === 0 || series.length === 0) {
        return (
            <div className="flex items-center justify-center w-full h-64">
                <div className="w-full h-full bg-gray-100 rounded-md flex items-center justify-center">
                    <p className="text-gray-500">Sem dados para exibir.</p>
                </div>
            </div>
        );
    }

    try {
        return (
            <Chart
                key={`budget-chart-${JSON.stringify(labels)}`}
                options={{
                    chart: {
                        id: 'budget-chart',
                        toolbar: {
                            show: false
                        },
                        redrawOnWindowResize: true,
                        redrawOnParentResize: true
                    },
                    colors: COLOR_PALETTE,
                    dataLabels: {
                        enabled: true,
                        formatter: function (val, opts) {
                            // Format the percentage
                            if (typeof val === "string") {
                                return `${val}%`;
                            }
                            if (typeof val === "number")
                                return val.toFixed(1) + '%';
                            return "";
                        }
                    },
                    labels: labels,
                    legend: {
                        show: false,
                        position: legendPosition,
                        fontSize: '12px'
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '65%',
                                labels: {
                                    show: false,
                                    name: {
                                        show: true,
                                        fontSize: '12px'
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '14px',
                                        formatter: function (val) {
                                            return 'R$ ' + parseFloat(val).toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            });
                                        }
                                    },
                                    total: {
                                        show: true,
                                        label: 'Total',
                                        formatter: function () {
                                            const total = series.reduce((sum, val) => sum + val, 0);
                                            return 'R$ ' + total.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return 'R$ ' + val.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });
                            }
                        }
                    }
                }}
                height={250}
                series={series}
                type="donut"
                width="100%"
            />
        );
    } catch (e) {
        console.error("Error rendering chart:", e);
        return (
            <div className="flex items-center justify-center w-full h-64">
                <div className="w-full h-full bg-red-50 rounded-md flex items-center justify-center">
                    <p className="text-red-500">Erro ao renderizar o gráfico.</p>
                </div>
            </div>
        );
    }
}