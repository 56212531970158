import { Button } from "../Button";
import SidebarModal from "./SidebarModal";

type ConfirmationModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    title: string;
    message: string;
}

export default function ConfirmationModal({ open, onClose, onConfirm, onCancel, title, message }: ConfirmationModalProps) {
    return (
        <SidebarModal
            title={title}
            action={<div className="flex w-full flex-col gap-2"><Button
                onClick={() => onConfirm()}
                className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                title={"Confirmar"}
            />
                <Button onClick={() => onCancel()}
                    className="bg-transparent hover:bg-transparent text-red-600 underline hover:no-underline" title="Cancelar" />
            </div>}
            open={open} onClose={onClose}>
            <div className="flex flex-col gap-3">
                <h4 className="block text-text font-primary leading-6 text-primaryText">{message}</h4>
            </div>
        </SidebarModal>
    )
}
