import { useEffect, useState } from "react";
import { useTransactionCategories } from "../../hooks/useTransactionCategories";
import { MonthlyTransactionFetchParams, TransactionCategory, TransactionCategoryType } from "../../models/types";
import CustomSelectDropdown from "./CustomSelectDropdown";
import PaidFilterDropdown from "./PaidFilterDropdown";
import TransactionTypeFilterDropdown from "./TransactionTypeFilterDropdown";

type MonthlyBudgetFiltersProps = {
    filters: MonthlyTransactionFetchParams;
    setFilters: (filters: MonthlyTransactionFetchParams) => void;
}
export const MonthlyBudgetFilters = ({ filters, setFilters }: MonthlyBudgetFiltersProps) => {
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [selectedType, setSelectedType] = useState<TransactionCategoryType | null>(null);
    const [selectedTransactionType, setSelectedTransactionType] = useState<"Todos" | "Receita" | "Despesa">("Todos");
    const [paid, setPaid] = useState<"Todos" | "Pago" | "Não pago">("Todos");

    useEffect(() => {
        if (selectedCategory) {
            setFilters({
                ...filters,
                category: selectedCategory.id,
                paid: paid === "Pago" ? true : paid === "Não pago" ? false : undefined,
                type: selectedTransactionType === "Receita" ? "credit" : selectedTransactionType === "Despesa" ? "debit" : undefined,
            })
        } else {
            setFilters({
                ...filters,
                category: undefined,
                paid: paid === "Pago" ? true : paid === "Não pago" ? false : undefined,
                type: selectedTransactionType === "Receita" ? "credit" : selectedTransactionType === "Despesa" ? "debit" : undefined,
            })
        }

    }, [selectedCategory, paid, selectedTransactionType])

    useEffect(() => {
        if (selectedType) {
            setFilters({
                ...filters,
                category_type: selectedType.id,
            })
        } else {
            setFilters({
                ...filters,
                category_type: undefined,
            })
        }
    }, [selectedType])

    useEffect(() => {
        if (selectedTransactionType) {
            setFilters({
                ...filters,
                type: selectedTransactionType === "Receita" ? "credit" : selectedTransactionType === "Despesa" ? "debit" : undefined,
            })
        } else {
            setFilters({
                ...filters,
                type: undefined,
            })
        }
    }, [selectedTransactionType])

    return <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center" style={{ position: 'relative', zIndex: 30 }}>
        <TransactionTypeFilterDropdown onChange={setSelectedTransactionType} value={selectedTransactionType} label="Tipo" />
        <PaidFilterDropdown onChange={setPaid} value={paid} label="Status" />
        <CustomSelectDropdown<TransactionCategory, MonthlyTransactionFetchParams>
            params={{ page_size: 100 }}
            label="Categoria"
            useGetOptions={useTransactionCategories}
            nameKey="name" onChange={setSelectedCategory} selected={selectedCategory}
            className="w-full md:max-w-xs"
        />
    </div>
}