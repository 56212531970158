import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { AdjustmentsHorizontalIcon, CurrencyDollarIcon, ScaleIcon } from "@heroicons/react/24/outline";
import { addMonths, format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import { useMonthBudgets, useMonthBudgetSummary } from "../../hooks/useMonthBudget";
import { monthBudgetColumns } from "../../pages/shared/BudgetTable";
import { formatBrazilianCurrence } from "../../utils/helper";
import { Button } from "../Button";
import MonthBudgetCopyModal from "../modals/MonthBudgetCopyModal";
import MonthBudgetModal from "../modals/MonthBudgetModal";
import TransactionCategoryModal from "../modals/TransactionCategoryModal";
import BaseTable from "../monthly-budget/Table";

export function BudgetSettings() {
    const [editCategoryId, setEditCategoryId] = useState<string | null>(null);
    const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
    const [selectedBudget, setSelectedBudget] = useState<string | null>(null);
    const [currentMonth, setCurrentMonth] = useState<string>(format(new Date(), "yyyy-MM-dd", { locale: ptBR }));
    const get_current_date = () => format(currentMonth, "MMMM/yyyy", { locale: ptBR })

    const { data: summary } = useMonthBudgetSummary({ month: parseISO(currentMonth).getMonth() + 1, year: parseISO(currentMonth).getFullYear() });
    return <>
        <TransactionCategoryModal open={createCategoryOpen} onClose={() => {
            setCreateCategoryOpen(false)
            setEditCategoryId(null);
        }} categoryId={editCategoryId} />
        <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row items-center gap-2">
                <ArrowLeftIcon className="h-3 w-3 cursor-pointer text-primary hover:text-secondaryText" onClick={() => setCurrentMonth(format(addMonths(parseISO(currentMonth), -1), "yyyy-MM-dd", { locale: ptBR }))} />
                <h1 className="text-primary font-primary capitalize">Orçamento {get_current_date()}</h1>
                <ArrowRightIcon className="h-3 w-3 cursor-pointer text-primary hover:text-secondaryText" onClick={() => setCurrentMonth(format(addMonths(parseISO(currentMonth), 1), "yyyy-MM-dd", { locale: ptBR }))} />
            </div>
        </div>
        <MonthBudgetModal
            open={open}
            onClose={() => {
                setOpen(false);
                setSelectedBudget(null);
            }}
            monthBudgetId={selectedBudget}
            month={parseISO(currentMonth).getMonth()}
            year={parseISO(currentMonth).getFullYear()}
        />
        <MonthBudgetCopyModal
            open={copyModalOpen}
            onClose={() => setCopyModalOpen(false)}
            currentMonth={currentMonth}
        />
        <div className="flex flex-row w-full gap-4 flex-wrap-reverse md:flex-nowrap">
            {summary && (!summary?.total_budget || (summary?.total_budget && summary.total_budget === 0)) ? <EmptyBudgetSettings onAdd={() => setOpen(true)} onCopy={() => setCopyModalOpen(true)} />
                : <><div className="flex flex-col gap-4 w-full items-center">
                    <BaseTable
                        className="rounded-md"
                        title={null}
                        params={{ month: parseISO(currentMonth).getMonth() + 1, year: parseISO(currentMonth).getFullYear() }}
                        onRowClick={(row) => {
                            setOpen(true);
                            setSelectedBudget(row.id);
                        }}
                        columns={monthBudgetColumns}
                        hidePagination
                        useGetData={useMonthBudgets}
                    />
                    <div className="flex flex-col sm:flex-row gap-2 w-full justify-center">
                        <Button
                            onClick={() => setCopyModalOpen(true)}
                            className="bg-green-600 hover:bg-green-700 rounded-2xl text-white"
                            title={"Copiar de outro mês"}
                        />
                        <Button
                            onClick={() => setOpen(true)}
                            className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white"
                            title={"+ Adicionar Categoria"}
                        />
                    </div>
                </div>
                    <BudgetSummary totalBudget={summary?.total_budget ?? 0} currentExpense={summary?.total_expense ?? 0} />

                </>
            }
        </div>
    </>
}

type EmptyBudgetSettingsProps = {
    onAdd: () => void;
    onCopy: () => void;
}

function EmptyBudgetSettings({ onAdd, onCopy }: EmptyBudgetSettingsProps) {
    return <div className="flex flex-grow bg-primaryBg flex-col items-center justify-center p-6 gap-2 border border-secondaryBorder rounded-lg border-dashed">
        <Button
            onClick={onCopy}
            className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white"
            title={"Copiar do mês anterior"}
        />
        <Button
            onClick={onAdd}
            className="bg-transparent hover:bg-transparent rounded-2xl text-primaryText underline hover:no-underline"
            title={"Novo orçamento"}
        />
    </div>
}

type BudgetSummaryProps = {
    totalBudget: number;
    currentExpense: number;
}

function BudgetSummary({
    totalBudget,
    currentExpense
}: BudgetSummaryProps) {
    return <div className="flex flex-grow h-fit max-w-full md:max-w-xs flex-col w-full gap-2 justify-center py-5 items-center shadow-lg rounded-2xl bg-primaryBg border border-primaryBorder">
        <div className="rounded-full h-44 w-44 bg-primaryBg border-4 border-primaryBorder flex flex-col gap-1 items-center justify-center">
            <h1 className="text-small font-small text-center">Restante para gastar</h1>
            <h1 className="text-big font-primary text-center">R$ {formatBrazilianCurrence(totalBudget - currentExpense)}</h1>
            <div className="bg-gray-200 text-text font-text py-1 px-2 rounded-md mt-1">de R$ {formatBrazilianCurrence(totalBudget)}</div>
        </div>
        <div className="flex mt-4 w-full flex-row items-center justify-between p-2 px-4 pb-0 border-t border-primaryBorder">
            <div className="flex flex-row items-center gap-1">
                <AdjustmentsHorizontalIcon className="h-6 w-6 min-w-6 min-h-6 text-primary bg-gray-100 text-gray-600 p-1 rounded-md" />
                <h1 className="text-primary font-primary capitalize">Orçamento Total</h1>
            </div>
            <h1 className="text-text font-primary flex">R$ {formatBrazilianCurrence(totalBudget)}</h1>
        </div>
        <div className="flex w-full flex-row items-center justify-between p-2 px-4 pb-0 border-t border-primaryBorder">
            <div className="flex flex-row items-center gap-1">
                <CurrencyDollarIcon className="h-6 w-6 min-w-6 min-h-6 text-primary bg-gray-100 text-gray-600 p-1 rounded-md" />
                <h1 className="text-primary font-primary capitalize">Gasto Atual</h1>
            </div>
            <h1 className="text-text font-primary flex">R$ {formatBrazilianCurrence(currentExpense)}</h1>
        </div>
        <div className="flex w-full flex-row items-center justify-between p-2 px-4 pb-0 border-t border-primaryBorder">
            <div className="flex flex-row items-center gap-1">
                <ScaleIcon className="h-6 w-6 min-w-6 min-h-6 text-primary bg-gray-100 text-gray-600 p-1 rounded-md" />
                <h1 className="text-primary font-primary capitalize">Saldo Restante</h1>
            </div>
            <h1 className="text-text font-primary flex">R$ {formatBrazilianCurrence(totalBudget - currentExpense)}</h1>
        </div>
    </div>
}