import { BanknotesIcon, ChartPieIcon, CreditCardIcon, FlagIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";

export default function EmptyTable() {
    return <div className="flex flex-grow w-full items-center min-w-full flex-col gap-2 py-6">
        <BanknotesIcon className="h-10 w-10 min-h-10 min-w-10 text-gray-500" />
        <h4 className="text-gray-500 text-md font-semibold">Nenhuma transação</h4>
        <h4 className="text-gray-500 text-sm">Nenhuma transação encontrada para o mês selecionado,<br /> adicione novas transações ou importe das despesas fixas</h4>
    </div>
}

export function EmptyTableCards() {
    return <div className="flex flex-grow w-full items-center min-w-full flex-col gap-2 py-6">
        <CreditCardIcon className="h-10 w-10 min-h-10 min-w-10 text-gray-500" />
        <h4 className="text-gray-500 text-md font-semibold">Nenhuma cartão cadastrado</h4>
        <h4 className="text-gray-500 text-sm">Adicione um cartão para acompanhar suas parcelas e transações</h4>
    </div>
}

export function BudgetEmptyState() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col w-full flex-grow bg-primaryBg border border-secondaryBorder rounded-xl px-4 py-6 shadow-lg">
            <div className="flex flex-col items-center justify-center text-center gap-3">
                <ChartPieIcon className="h-16 w-16 text-gray-300" />
                <h3 className="text-lg font-medium text-gray-700">Sem orçamento definido</h3>
                <p className="text-sm text-gray-500 max-w-xs">
                    Configure o orçamento para o mês selecionado e acompanhe seus gastos mensais.
                </p>
                <Button
                    onClick={() => navigate("/orcamento")}
                    className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white"
                    title={"Configurar Orçamento"}
                />
            </div>
        </div>
    );
}

export function GoalEmptyState() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col w-full bg-primaryBg border border-secondaryBorder rounded-xl px-4 py-6 shadow-lg">
            <div className="flex flex-col items-center justify-center text-center gap-3">
                <FlagIcon className="h-16 w-16 text-gray-300" />
                <h3 className="text-lg font-medium text-gray-700">Nenhuma meta definida</h3>
                <p className="text-sm text-gray-500 max-w-xs">
                    Crie metas financeiras para acompanhar seu progresso e alcançar seus objetivos.
                </p>
                <Button
                    onClick={() => navigate("/metas")}
                    className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white"
                    title={"Criar Meta"}
                />
            </div>
        </div>
    );
}