
export default function HelpPage() {
    return (
        <div className="flex w-full flex-col gap-4 justify-start">

            <div className="flex flex-col p-6 shadow-md rounded-lg bg-primaryBg border border-primaryBorder">
                <div className="flex flex-col items-center text-center mb-8">
                    <h2 className="font-primary text-big text-primaryText mb-4">Suporte</h2>
                    <p className="font-text text-text text-thirdText max-w-2xl">
                        Precisa de ajuda? Estamos sempre disponíveis para ajudar você.
                        Entre em contato através de qualquer um dos nossos canais de atendimento.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
                    <div className="flex flex-col border border-primaryBorder rounded-xl min-w-60 hover:shadow-md transition-all duration-300 overflow-hidden">
                        <div className="flex items-center px-6 py-3 gap-3 bg-primaryBg w-full border-b border-primaryBorder rounded-t-xl">
                            <h3 className="text-primary font-primary">E-mail</h3>
                        </div>
                        <div className="bg-white w-full p-6 rounded-b-xl flex flex-col items-center text-center">
                            <div className="bg-blue-100 p-4 rounded-full mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </div>
                            <p className="font-text text-text text-thirdText mb-4">Envie-nos um e-mail e responderemos o mais rápido possível</p>
                            <a href="mailto:contato@maisumnabolsa.com.br" className="font-accent text-accent text-blue-600 hover:text-blue-800 transition-colors">
                                contato@maisumnabolsa.com.br
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col border border-primaryBorder rounded-xl min-w-60 hover:shadow-md transition-all duration-300 overflow-hidden">
                        <div className="flex items-center px-6 py-3 gap-3 bg-primaryBg w-full border-b border-primaryBorder rounded-t-xl">
                            <h3 className="text-primary font-primary">WhatsApp</h3>
                        </div>
                        <div className="bg-white w-full p-6 rounded-b-xl flex flex-col items-center text-center">
                            <div className="bg-green-100 p-4 rounded-full mb-5">
                                <img src="/images/whatsapp.png" alt="WhatsApp" className="h-10 w-10" />
                            </div>
                            <p className="font-text text-text text-thirdText mb-4">Atendimento rápido e direto pelo WhatsApp</p>
                            <button
                                onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1%2C%20preciso%20de%20ajuda", "_blank")}
                                className="flex items-center justify-center gap-2 bg-green-600 text-white px-5 py-2 rounded-md hover:bg-green-700 transition-colors"
                            >
                                <span className="font-accent text-accent">(11) 99358-1886</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="text-center mt-8 font-small text-small text-gray-500">
                    <p>Horário de atendimento: Segunda a Sexta das 9h às 18h</p>
                </div>
            </div>
        </div>
    );
}