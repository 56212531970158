import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { getMonthlyTransactionsSummaryByCategory } from '../../services/MonthlyTransactionService';

interface ExpenseByCategoryChartProps {
    month: string; // YYYY-MM-DD format
}

// Define Tailwind color palette
const COLOR_PALETTE = [
    '#10B981', // emerald-500
    '#3B82F6', // blue-500
    '#6366F1', // indigo-500
    '#8B5CF6', // violet-500
    '#EC4899', // pink-500
    '#F43F5E', // rose-500
    '#F59E0B', // amber-500
    '#84CC16', // lime-500
];

const ExpenseByCategoryChart: React.FC<ExpenseByCategoryChartProps> = ({ month }) => {
    const [chartData, setChartData] = useState<{
        categories: string[];
        amounts: number[];
        totalAmount: number;
    }>({
        categories: [],
        amounts: [],
        totalAmount: 0
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchCategoryData = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await getMonthlyTransactionsSummaryByCategory({ month });

                if (response.status === 200 && response.data.results) {
                    // Filter out zero-amount entries
                    const filteredData = response.data.results.filter(cat =>
                        (cat.total_amount || 0) > 0
                    );

                    // Sort categories by total amount (descending)
                    const sortedData = [...filteredData].sort((a, b) =>
                        (b.total_amount || 0) - (a.total_amount || 0)
                    );

                    // Take top 5 categories
                    const topCategories = sortedData.slice(0, 5);

                    // Extract category names and amounts
                    const categoryNames = topCategories.map(cat => cat.name || 'Sem categoria');
                    const categoryAmounts = topCategories.map(cat => Number(cat.total_amount) || 0);
                    const totalAmount = categoryAmounts.reduce((sum, amount) => sum + amount, 0);

                    setChartData({
                        categories: categoryNames,
                        amounts: categoryAmounts,
                        totalAmount
                    });
                } else {
                    setChartData({
                        categories: [],
                        amounts: [],
                        totalAmount: 0
                    });
                }
            } catch (err) {
                console.error('Error fetching category data:', err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryData();
    }, [month]);

    if (loading) {
        return (
            <div className="flex items-center justify-center w-full h-72">
                <div className="animate-pulse w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
                    <p className="text-gray-500">Carregando dados...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center w-full h-72">
                <div className="w-full h-full bg-red-50 rounded-md flex items-center justify-center">
                    <p className="text-red-500">Erro ao carregar dados. Tente novamente mais tarde.</p>
                </div>
            </div>
        );
    }

    if (chartData.categories.length === 0) {
        return (
            <div className="flex flex-col w-full bg-primaryBg px-4 py-6">
                <div className="flex flex-col items-center justify-center text-center gap-3">
                    <CurrencyDollarIcon className="h-16 w-16 text-gray-300" />
                    <h3 className="text-lg font-medium text-gray-700">Nenhuma despesa cadastrada</h3>
                    <p className="text-sm text-gray-500 max-w-xs">
                        Você ainda não adicionou nenhuma despesa no mês selecionado
                    </p>
                </div>
            </div>
        );
    }

    // Bar chart configuration
    const options: any = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
            redrawOnWindowResize: true,
            redrawOnParentResize: true
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                columnWidth: '65%',
                distributed: true,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        grid: {
            padding: {
                top: 30,
                right: 10,
                bottom: 20,
                left: 10
            }
        },
        colors: COLOR_PALETTE.slice(0, chartData.categories.length),
        dataLabels: {
            enabled: true,
            formatter: function (val: number) {
                if (val >= 1000) {
                    return 'R$ ' + (val / 1000).toFixed(1) + 'k';
                }
                return 'R$ ' + val.toFixed(0);
            },
            style: {
                fontSize: '10px',
                colors: ['#333']
            },
            offsetY: -20
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: chartData.categories,
            labels: {
                style: {
                    fontSize: '10px'
                },
                // Truncate long category names and rotate
                formatter: function (val: string) {
                    return val.length > 10 ? val.substring(0, 8) + '...' : val;
                },
                rotate: -45
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '10px'
                },
                formatter: function (val: number) {
                    if (val >= 1000) {
                        return 'R$ ' + (val / 1000).toFixed(1) + 'k';
                    }
                    return 'R$ ' + val.toFixed(0);
                }
            }
        },
        tooltip: {
            y: {
                formatter: function (val: number) {
                    return 'R$ ' + val.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                }
            }
        },
    };

    return (
        <div className="w-full overflow-hidden">
            <Chart
                options={options}
                series={[{
                    name: 'Despesas',
                    data: chartData.amounts
                }]}
                type="bar"
                height={280}
                width="100%"
            />
        </div>
    );
};

export default ExpenseByCategoryChart;
