import queryClient, { QueryKey } from "../hooks/state";
import { CourseModuleClass, CourseModuleClassFilterParams } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/course/classes",
  retrieve: (id: string) => `/course/classes/${id}`,
  complete: (id: string) => `/course/classes/${id}/complete`,
};

export const getCompleteClass = async (id: string) => {
  const data = await httpGetAuthenticated<{ message: string }>(endpoints.complete(id));
  queryClient.invalidateQueries([QueryKey.courseModules]);
  return data;

}

export const getCourseModuleClass = async (objectId: string) => {
  return httpGetAuthenticated<CourseModuleClass>(endpoints.retrieve(objectId));
}

export const getCourseModuleClasses = async (params: CourseModuleClassFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<CourseModuleClass>>(endpoints.list(), {
    params,
  });
}

export const createCourseModuleClass = async (payload: {
  title: string;
  description: string;
  external_id: string;
  module: string;
  class_number: number;
}) => {
  const response = await httpPostAuthenticated<CourseModuleClass>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.courseModuleClass);
  return response.data;
}

export const updateCourseModuleClass = async (id: string, payload: {
  title: string;
  description: string;
  external_id: string;
  class_number: number;
}) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.courseModuleClass);
  return response.data;
}

export const deleteCourseModuleClass = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.courseModuleClass);
  return response.data;
}