import { CalculadoraJurosCompostos } from '../components/IndependenciaFinanceira/CalculadoraJurosCompostos';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function JurosCompostosPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-3">
      {/* Botão de voltar */}
      <div className="mb-4">
        <button
          onClick={() => navigate('/simuladores')}
          className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors duration-200"
        >
          <ArrowLeftIcon className="h-4 w-4" />
          <span>Voltar</span>
        </button>
      </div>
      
      {/* Cabeçalho */}
      <div className="mb-2">
        <h1 className="text-xl font-semibold text-gray-900">Calculadora de Juros Compostos</h1>
        <p className="text-sm text-gray-600">
          Calcule o impacto dos juros compostos nos seus investimentos e veja como seu dinheiro pode crescer ao longo do tempo.
        </p>
      </div>
      
      <CalculadoraJurosCompostos />
    </div>
  );
}