import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { formatBrazilianCurrence } from "../../utils/helper";

type BalanceWidgetProps = {
    label: string;
    value?: number | string;
    icon?: React.ReactNode;
    iconClassName?: string;
}
export default function BalanceWidget(props: BalanceWidgetProps) {
    return <div className="flex gap-2 w-[48%] flex-grow md:w-fit md:min-w-44 md:max-w-44 flex-row items-center p-2 shadow-lg rounded-lg bg-primaryBg border border-primaryBorder overflow-hidden text-ellipsis">
        {props.icon ? props.icon : <CurrencyDollarIcon className={twMerge("h-10 w-10 min-h-10 min-w-10 text-green-600 bg-green-100 p-1 rounded-md", props.iconClassName)} />}
        <div className="flex flex-col w-full justify-between items-start gap-1">
            <h2 className="font-small text-small text-gray-500">{props.label}</h2>
            <h2 className="font-text text-text overflow-hidden text-ellipsis whitespace-nowrap w-full max-w-[110px]">R$ {formatBrazilianCurrence(props.value ?? 0)}</h2>
        </div>
    </div>
}

export function BalanceWidgetPaywall(props: BalanceWidgetProps) {
    return <div className="flex gap-2 w-[48%] flex-grow md:w-fit md:min-w-44 md:max-w-44 flex-row items-center p-2 shadow-lg rounded-lg bg-primaryBg border border-primaryBorder overflow-hidden text-ellipsis">
        {props.icon ? props.icon : <CurrencyDollarIcon className={twMerge("h-10 w-10 min-h-10 min-w-10 text-green-600 bg-green-100 p-1 rounded-md", props.iconClassName)} />}
        <div className="flex flex-col w-full justify-between items-start gap-1">
            <h2 className="font-small text-small text-gray-500">{props.label}</h2>
            <h2 className="font-text text-text overflow-hidden text-ellipsis whitespace-nowrap w-full max-w-[110px]">R$ 0,00</h2>
        </div>
    </div>
}