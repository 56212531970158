import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useMonthBudget } from "../../hooks/useMonthBudget";
import { useTransactionCategories } from "../../hooks/useTransactionCategories";
import { TransactionCategory, TransactionCategoryFilterParams } from "../../models/types";
import { createMonthBudget, deleteMonthBudget, updateMonthBudget } from "../../services/MonthBudgetService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CategoryBadge } from "../Badge";
import { Button } from "../Button";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import ConfirmationModal from "./ConfirmationModal";
import SidebarModal from "./SidebarModal";

type BaseModalProps = {
    open: boolean;
    onClose: () => void;
    monthBudgetId: string | null;
    month: number;
    year: number;
}

export default function MonthBudgetModal({ open, onClose, monthBudgetId, month, year }: BaseModalProps) {
    const [expense, setExpense] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [amount, setAmount] = useState<string>("0");
    const [amountError, setAmountError] = useState<boolean>(false);
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

    const { setNotification } = useAppContext();

    const { data: budget } = useMonthBudget(monthBudgetId ?? "");

    useEffect(() => {
        if (monthBudgetId && budget) {
            setAmount(`${budget.budget_amount}`.replace(".", ","));
            setSelectedCategory(budget.category_name)
            setExpense(budget.category_name.category_type === "expense")
        } else {
            setAmount("0");
            setSelectedCategory(null)
            setExpense(true)
        }
    }, [open, budget]);

    const handleCreate = async () => {
        try {

            setLoading(true);
            let hasError = false;
            if (!amount || amount === "0,00" || amount === "" || amount === "0") {
                setAmountError(true);
                hasError = true;
            } else {
                setAmountError(false);
            }
            if (!selectedCategory) {
                setCategoryError(true);
                hasError = true;
            } else {
                setCategoryError(false);
            }

            if (hasError) return;

            if (monthBudgetId) {
                await updateMonthBudget(monthBudgetId, {
                    budget_amount: parseFloat(amount.replace(",", ".")),
                })
            } else {
                await createMonthBudget({
                    budget_amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    month: month + 1,
                    year: year,
                })
            }
            setNotification({
                type: 'success',
                message: `Categoria ${monthBudgetId ? "editada" : "adicionada"} com sucesso`,
                title: `Categoria ${monthBudgetId ? "editada" : "adicionada"}`
            })
            onClose();
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao adicionar categoria'
            })
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteTransaction = async () => {
        try {
            if (monthBudgetId) {
                await deleteMonthBudget(monthBudgetId);
                setNotification({
                    type: 'success',
                    message: 'Categoria removida do orçamento com sucesso',
                    title: 'Categoria removida'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhuma categoria selecionada',
                    title: 'Erro ao remover categoria'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir transação'
            })
        }
    }

    return (
        <SidebarModal
            onDelete={monthBudgetId ? () => setOpenConfirmation(true) : undefined}
            open={open}
            onClose={onClose}
            title={`${monthBudgetId ? "Editar" : "Adicionar"} categoria ao orçamento`}
            action={<Button
                disabled={loading}
                loading={loading}
                onClick={() => handleCreate()}
                className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                title={monthBudgetId ? "Salvar" : "Adicionar"}
            />}
        >
            <div className="flex flex-col gap-2 flex-grow mt-2">
                <h4 className="text-xs font-semibold text-gray-700">Valor</h4>
                <div className={`flex flex-row gap-1 items-center ${amountError ? "border-b border-red-600" : ""}`}>
                    <h4 className="text-sm font-semibold">R$</h4>
                    <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="0,00"
                        value={amount}
                        onFocus={(e) => {
                            if (e.target.value === "0,00") {
                                setAmount("");
                            }
                        }}
                        onChange={(e) => {
                            setAmountError(false);
                            handleMoneyChange(e, setAmount)
                        }}
                        className={`p-0 text-lg border-none font-semibold focus:ring-0 focus:outline-none focus:border-b-2 focus:underline cursor-pointer focus:border-blue-500`}
                    />
                </div>
                {amountError && <h4 className="px-2 text-xs font-light text-red-600">O valor é obrigatório</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Categoria</h4>
                <CustomSelectDropdown<TransactionCategory, TransactionCategoryFilterParams>
                    params={{ page_size: 100, category_type: expense ? "expense" : "income" }}
                    secondComponent={(category) => <CategoryBadge category={category} />}
                    useGetOptions={useTransactionCategories} nameKey="name" onChange={(v) => {
                        setSelectedCategory(v);
                        setCategoryError(false);
                    }} selected={selectedCategory} />
                {categoryError && <h4 className="px-4 text-xs font-light text-red-600">A categoria é obrigatória</h4>}
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteTransaction()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Remover categoria"
                    message={`Deseja realmente remover a categoria ${budget?.category_name.name} do orçamento?`} />
            </div>
        </SidebarModal >
    )
}
