import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useAppContext } from "../context/AppContext";
import { useUser } from "../hooks/useUsers";
import { File } from "../models/types";
import { getFile } from "../services/FileService";
import { BaseBadge } from "./Badge";
import BaseCard from "./BaseCard";
import { Button } from "./Button";

export default function FileCard({ file, onEdit }: { file: File, onEdit?: (fileId: string) => void }) {
    const { setNotification } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { data: user } = useUser();

    const handleDownload = async () => {
        try {
            setLoading(true);
            const { data } = await getFile(file.id);
            if (data.file) {
                window.open(data.file, "_blank");
            }
        } catch (error) {
            setNotification({
                message: "Erro ao baixar arquivo",
                type: "error",
                title: "Erro"
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseCard
            className="w-full md:min-w-[48%] md:max-w-[48%] lg:min-w-[48%] lg:max-w-[48%] xl:min-w-[30%] xl:max-w-[30%] shadow-lg hover:shadow-xl transition-shadow"
            icon={<ArchiveBoxIcon className="h-6 w-6 min-h-6 min-w-6 text-primary" />}
            title={file.name}
            onClick={() => onEdit && onEdit(file.id)}
            hideClick={!user?.is_admin}
            content={
                <div className="flex flex-col h-full justify-between flex-grow px-2 gap-2">
                    <div className="flex flex-row flex-grow items-start pr-1 border-b border-primaryBorder py-2">
                        <span className="text-sm leading-5 whitespace-pre-wrap font-text text-primaryText max-h-36 truncate">
                            {file.description}
                        </span>
                    </div>
                    <div className="flex w-full justify-between items-center my-2">
                        <BaseBadge title={file.category} />
                        <Button
                            loading={loading}
                            title="Baixar Material"
                            className="w-full max-w-32 text-center"
                            onClick={() => handleDownload()}
                        />
                    </div>
                </div>
            }
        />
    );
}