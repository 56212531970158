import { useQuery } from "react-query";
import { GoalFilterParams, GoalTransaction } from "../models/types";
import { addGoalTransaction, getGoal, getGoalTransactions, getGoals, removeGoalTransaction } from "../services/GoalService";
import { QueryKey } from "./state";

export const useGoal = (objectId: string | null) => {
    return useQuery([QueryKey.goals, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getGoal(objectId);
        return data;
    });
}

export const useGoals = (params: GoalFilterParams) => {
    return useQuery([QueryKey.goals, params], async () => {
        const { data } = await getGoals(params);
        return data;
    });
}

export const useGoalTransactions = (goalId: string | null) => {
    return useQuery([QueryKey.goals, goalId, 'transactions'], async () => {
        if (!goalId || (goalId && goalId === "")) return [];
        const data = await getGoalTransactions(goalId);
        return data;
    });
};

export const addTransaction = async (goalId: string, amount: number, description: string) => {
    return addGoalTransaction(goalId, {
        amount,
        description
    });
};

export const removeTransaction = async (goalId: string, transactionId: string) => {
    return removeGoalTransaction(goalId, transactionId);
};

