import { ArrowLeftIcon, CheckCircleIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, ChevronUpIcon, PlayPauseIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { useCourseModuleClass } from "../hooks/useCourseModulesClasses";
import { useCourse, useCourseModules } from "../hooks/useCourses";
import { CourseModule } from "../models/types";
import { getCompleteClass } from "../services/CourseModuleClassService";
import { getNextClass } from "../services/CourseModuleService";
import { getCurrentClass } from "../services/CourseService";
import { Button } from "./Button";
import CourseProgress from "./CourseProgress";
import LoadingSpinner from "./Loading";
import Video from "./Video";

/* eslint-disable jsx-a11y/iframe-has-title */
export const AulaComponent = () => {
    const { setNotification } = useAppContext();
    const { courseId } = useParams();
    const [classId, setClassId] = useState<string | null>(null);
    const navigate = useNavigate();
    if (!courseId) {
        navigate("/inicio")
    }
    const [loading, setLoading] = useState<boolean>(false);
    
    // Ref to track if a navigation is already in progress
    const isNavigatingRef = useRef<boolean>(false);
    // Ref to track the last navigation timestamp to prevent rapid subsequent calls
    const lastNavigationTimeRef = useRef<number>(0);

    const loadFirstClass = async () => {
        if (courseId && !classId) {
            const { data } = await getCurrentClass(courseId);
            if (data.class_id) {
                setClassId(data.class_id);
            }
        }
    }
    // Reset classId when course changes
    useEffect(() => {
        setClassId(null);
    }, [courseId]);
    
    // Load the first class when needed
    useEffect(() => {
        // Only load the first class when we don't have a classId
        if (!classId) {
            loadFirstClass();
        }
    }, [courseId, classId]);

    const handleNext = async () => {
        // Prevent multiple calls in quick succession (at least 2 second gap)
        const now = Date.now();
        const minTimeBetweenCalls = 2000; // 2 seconds
        
        // If a navigation is already in progress or was called too recently, ignore this call
        if (isNavigatingRef.current || (now - lastNavigationTimeRef.current < minTimeBetweenCalls)) {
            console.log("Navigation already in progress or called too recently. Ignoring this call.");
            return;
        }
        
        // Mark that we're starting navigation and update timestamp
        isNavigatingRef.current = true;
        lastNavigationTimeRef.current = now;
        
        try {
            setLoading(true);
            if (!classId || !courseModuleClass) {
                throw new Error("No class selected");
            }
            
            // Store the current classId to prevent race conditions
            const currentClassId = classId;
            const currentModuleId = courseModuleClass.module;
            
            // Mark the current class as complete
            await getCompleteClass(currentClassId);
            
            // Check if we're still on the same class (prevents race conditions)
            if (currentClassId !== classId) {
                console.log("Class changed during navigation. Aborting.");
                return;
            }
            
            // Check if we need to go to the next class
            const { data: nextClass } = await getNextClass(currentModuleId, currentClassId, true);
            
            // Check again for race conditions
            if (currentClassId !== classId) {
                console.log("Class changed during navigation. Aborting.");
                return;
            }
            
            // If we have a next class, go to it
            if (nextClass.class_id) {
                setClassId(nextClass.class_id);
            } 
            // If there's no next class and the course isn't already completed
            else if (course && !course.progress.is_completed) {
                navigate(`/curso/${courseId}/finalizado`);
                setNotification({
                    message: "Módulo finalizado com sucesso",
                    title: "Módulo Finalizado",
                    type: "success"
                });
            } 
            // If we're rewatching a completed course and this was the last class of a module
            else {
                // Stay on the same page but show a notification
                setNotification({
                    message: "Você já concluiu este curso!",
                    title: "Curso Completo",
                    type: "success"
                });
            }
        } catch (err) {
            console.error("Error in handleNext:", err);
            setNotification({
                message: "Erro ao carregar próxima aula",
                title: "Erro",
                type: "error"
            });
        } finally {
            setLoading(false);
            // Add a small delay before allowing new navigations to prevent rapid calls
            setTimeout(() => {
                isNavigatingRef.current = false;
            }, 500);
        }
    }

    const { data: course, isLoading: loadingCourse } = useCourse(courseId ?? "");
    const { data: modules, isLoading: loadingModules } = useCourseModules(courseId ?? "");
    const { data: courseModuleClass, isLoading: loadingClass } = useCourseModuleClass(classId ?? "");

    const isLoading = loadingCourse || loadingClass || loadingModules || loading;
    return isLoading || !course || !modules || !courseModuleClass ? <LoadingSpinner /> : <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-1 cursor-pointer" onClick={() => navigate(`/cursos`)}>
            <ArrowLeftIcon className="h-3 w-3 text-primaryText" />
            <h1 className="text-text font-text text-primaryText hover:text-secondaryText">Voltar</h1>
        </div>
        <div className="flex flex-col lg:flex-row flex-grow w-full gap-4">
            <div className="flex flex-col flex-grow gap-4">
                <Video onEnd={handleNext} videoId={courseModuleClass.external_id} title={courseModuleClass.title} />
                <div className="flex flex-row w-full justify-between items-center">
                    <h1 className="text-big font-primary text-primaryText">{courseModuleClass.title}</h1>
                    <Button 
                        className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white" 
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleNext()} 
                        title="Próxima Aula" 
                    />
                </div>
                <h1 className="text-primary font-text">{courseModuleClass.description}</h1>
            </div>
            <div className="flex flex-col w-full lg:min-w-80 lg:max-w-80 rounded-lg flex-grow-0 self-start shadow-lg bg-primaryBg border border-secondaryBorder">
                {modules?.map((module, idx) => <ModuleCard setClassId={setClassId} currentClass={classId} key={module.id} module={module} />)}
            </div>
        </div>
    </div>
}

function ModuleCard({ module, currentClass, setClassId }: { module: CourseModule; currentClass: string | null; setClassId: (id: string) => void }) {
    const [collapsed, setCollapsed] = useState<boolean>(!module.classes.some((cl) => cl.id === currentClass));
    const completedClasses = module.classes.filter((cl) => cl.completed).length;
    return <div className={`flex flex-col ${collapsed ? "border-secondaryBorder border-b" : ""}`}>
        <div className={`flex p-2 flex-row items-center justify-between w-full cursor-pointer ${collapsed ? "" : "pb-0"}`} onClick={() => setCollapsed(!collapsed)}>
            <div className="flex flex-col gap-2 flex-grow">
                <h1 className="text-primary font-primary">
                    {module.module_number}. {module.title}
                </h1>
                <div className="flex flex-row items-center gap-2 w-full pr-2">
                    <h1 className="text-text font-text min-w-16">
                        {completedClasses}/{module.classes.length} aulas
                    </h1>
                    <CourseProgress hideText progress={{
                        total: module.classes.length,
                        completed: completedClasses,
                        is_completed: completedClasses === module.classes.length
                    }} />
                </div>
            </div>
            {collapsed && <ChevronDownIcon className="h-4 w-4 text-primaryText" />}
            {!collapsed && <ChevronUpIcon className="h-4 w-4 text-primaryText" />}
        </div>
        {!collapsed && <div className="flex flex-col border-secondaryBorder border-b p-2 gap-2">
            {module.classes.map((cl) => <div key={cl.id} onClick={() => setClassId(cl.id)} className={`cursor-pointer flex flex-row items-center hover:bg-gray-100 hover:rounded-lg gap-2 p-2 ${cl.id === currentClass ? "bg-gray-200 rounded-lg" : ""}`}>
                {cl.completed && <CheckCircleIcon className="h-5 w-5 text-green-600" />}
                {!cl.completed && cl.id === currentClass && <PlayPauseIcon className="h-5 w-5 text-blue-600" />}
                {!cl.completed && !(cl.id === currentClass) && <PlayCircleIcon className={`h-5 w-5  ${cl.completed ? "text-green-600" : "text-blue-600"}`} />}
                <h1 className="text-text font-text">{cl.title}</h1>
            </div>
            )}
        </div>}
    </div>
}