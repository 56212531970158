import Card from '../Card';
import Chart from 'react-apexcharts';
import { FinancialIndependenceResult } from '../../models/types';

type GraficoIndependenciaFinanceiraProps = {
  resultado: FinancialIndependenceResult;
}

export const GraficoIndependenciaFinanceira = ({ resultado }: GraficoIndependenciaFinanceiraProps) => {
  // Função para formatar valores monetários
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Mostrar apenas os primeiros e últimos anos para não sobrecarregar a tabela
  const dadosVisualizacao = resultado.projecao_patrimonio.length <= 5 
    ? resultado.projecao_patrimonio 
    : [
        resultado.projecao_patrimonio[0], 
        ...resultado.projecao_patrimonio.slice(-4)
      ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Card da tabela */}
      <Card>
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Tabela de Evolução</h2>
        <p className="text-sm text-gray-600 mb-2">
          Valores detalhados ao longo do período de investimento.
        </p>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ano
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Aportado
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Juros Acumulados
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Patrimônio Total
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dadosVisualizacao.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    Ano {item.ano}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatCurrency(item.aporte_acumulado)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatCurrency(item.juros_acumulados)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatCurrency(item.patrimonio)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      
      {/* Card do gráfico */}
      <Card>
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Evolução do Patrimônio</h2>
        <p className="text-sm text-gray-600 mb-2">
          Visualização do crescimento patrimonial até a independência financeira.
        </p>
        
        <div className="h-80">
          <Chart
            options={{
              chart: {
                type: 'area',
                height: 350,
                stacked: false,
                toolbar: {
                  show: false
                }
              },
              colors: ['#4F46E5', '#10B981', '#F59E0B'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth',
                width: [3, 3, 3]
              },
              xaxis: {
                categories: resultado.projecao_patrimonio.map(item => `Ano ${item.ano}`)
              },
              yaxis: {
                labels: {
                  formatter: function (value: number) {
                    if (value >= 1000000) {
                      return `${(value / 1000000).toFixed(1)}M`;
                    } else if (value >= 1000) {
                      return `${(value / 1000).toFixed(0)}K`;
                    }
                    return value.toFixed(0);
                  }
                }
              },
              tooltip: {
                y: {
                  formatter: function (value: number) {
                    return formatCurrency(value);
                  }
                }
              },
              legend: {
                position: 'top',
                horizontalAlign: 'right'
              }
            }}
            series={[
              {
                name: 'Patrimônio Total',
                data: resultado.projecao_patrimonio.map(item => Math.round(item.patrimonio))
              },
              {
                name: 'Total Aportado',
                data: resultado.projecao_patrimonio.map(item => Math.round(item.aporte_acumulado))
              },
              {
                name: 'Juros Acumulados',
                data: resultado.projecao_patrimonio.map(item => Math.round(item.juros_acumulados))
              }
            ]}
            type="area"
            height="100%"
          />
        </div>
      </Card>
    </div>
  );
};