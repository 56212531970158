import { CreateAccountData, LoginResponse, User } from "../models/types";
import { httpGetUnauthenticated, httpPostAuthenticated, httpPostUnauthenticated } from "./Api";

const loginEndpoints = {
    login: () => "/central/auth",
    signup: () => "/central/signup",
    password: () => "/central/change_password",
    magic_link: () => "/central/central/magic_link",
    forgot_password: () => "/central/forgot_password",
    reset_password: () => "/central/reset_password"
};

export const getLogin = async (payload: {
    username: string; password: string;
}) => {
    return httpPostAuthenticated<LoginResponse>(loginEndpoints.login(), payload);
}

export const createAccount = async (payload: CreateAccountData) => {
    return httpPostAuthenticated<User & { access_token: string }>(loginEndpoints.signup(), payload);
}

export const changePassword = async (payload: { old_password: string; new_password: string }) => {
    return httpPostAuthenticated(loginEndpoints.password(), payload);
}

export const getLoginMagicLink = async (token: string) => {
    return httpGetUnauthenticated<LoginResponse>(loginEndpoints.magic_link(), {
        params: {
            token,
        }
    });
}

// Request a password reset link to be sent to user's email
export const requestPasswordReset = async (email: string) => {
    return httpPostUnauthenticated(loginEndpoints.forgot_password(), { email });
}

// Reset password using a token received via email
export const resetPassword = async (token: string, new_password: string) => {
    return httpPostUnauthenticated<LoginResponse>(loginEndpoints.reset_password(), { token, new_password });
}