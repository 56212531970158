import { useQuery } from "react-query";
import { MonthlyBalance } from "../models/types";
import { getMonthlyBalance } from "../services/MonthlyTransactionService";
import queryClient, { QueryKey } from "./state";

export const useMonthlyBalance = (month: string, months: number = 12) => {
  return useQuery<MonthlyBalance[], Error>(
    [QueryKey.monthlyBalance, month, months],
    async () => {
      const response = await getMonthlyBalance(month, months);
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes
    }
  );
};

export const invalidateMonthlyBalance = () => {
  queryClient.invalidateQueries(QueryKey.monthlyBalance);
};