import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { addMonths, format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import AISummary from "../components/AISummary";
import AISummaryPaywall from "../components/AISummaryPaywall";
import BudgetSummary from "../components/BudgetSummary";
import BudgetSummaryPaywall from "../components/BudgetSummaryPaywall";
import ExpenseByCategoryChart from "../components/charts/ExpenseByCategoryChart";
import MonthOverallChart from "../components/charts/MonthOverallChart";
import ExpenseByCategoryChartPaywall from "../components/ExpenseByCategoryChartPaywall";
import GoalSummary from "../components/GoalSummary";
import GoalSummaryPaywall from "../components/GoalSummaryPaywall";
import BalanceWidget, { BalanceWidgetPaywall } from "../components/home/BalanceWidget";
import MonthOverallChartPaywall from "../components/MonthOverallChartPaywall";
import StackedSummary from "../components/StackedSummary";
import StackedSummaryPaywall from "../components/StackedSummaryPaywall";
import { useMonthlyTransactionsSummary } from "../hooks/useMonthlyTransactions";
import { useUser } from "../hooks/useUsers";

export default function HomePage() {
    const [currentMonth, setCurrentMonth] = useState<string>(format(new Date(), "yyyy-MM-dd", { locale: ptBR }));
    const { data: summary } = useMonthlyTransactionsSummary({ month: currentMonth });
    const { data: user } = useUser();

    const isSubscribed = user?.plan && user?.plan !== "FREE";
    const get_current_date = () => format(currentMonth, "MMMM yyyy", { locale: ptBR })

    return <>
        <div className="flex flex-col w-full gap-4 justify-start">
            {isSubscribed && <div className="flex flex-col w-full gap-2">
                <div className="flex flex-row items-center gap-2">
                    <ArrowLeftIcon className="h-3 w-3 cursor-pointer text-primary hover:text-secondaryText" onClick={() => setCurrentMonth(format(addMonths(parseISO(currentMonth), -1), "yyyy-MM-dd", { locale: ptBR }))} />
                    <h1 className="text-primary font-primary capitalize">{get_current_date()}</h1>
                    <ArrowRightIcon className="h-3 w-3 cursor-pointer text-primary hover:text-secondaryText" onClick={() => setCurrentMonth(format(addMonths(parseISO(currentMonth), 1), "yyyy-MM-dd", { locale: ptBR }))} />
                </div>
                <div className="flex w-full flex-row flex-wrap gap-2 justify-between md:justify-start">
                    <BalanceWidget label="Receitas" value={summary?.income_transactions_sum} iconClassName="" />
                    <BalanceWidget label="Despesas" value={summary?.total_transactions_sum} iconClassName="text-red-600 bg-red-100" />
                    <BalanceWidget label="Balanço" value={(summary?.income_transactions_sum ?? 0) - (summary?.total_transactions_sum ?? 0)}
                        icon={<ScaleIcon className={"h-10 w-10 min-h-10 min-w-10 text-blue-600 bg-blue-100 p-1 rounded-md"} />} />
                </div>
            </div>}
            {!isSubscribed && <div className="flex flex-col w-full gap-2">
                <div className="flex w-full flex-row flex-wrap gap-2 justify-between md:justify-start">
                    <BalanceWidgetPaywall label="Receitas" value="" />
                    <BalanceWidgetPaywall label="Despesas" value="" iconClassName="text-red-600 bg-red-100" />
                    <BalanceWidgetPaywall label="Balanço" value=""
                        icon={<ScaleIcon className={"h-10 w-10 min-h-10 min-w-10 text-blue-600 bg-blue-100 p-1 rounded-md"} />} />
                </div>
            </div>}
            {/* Charts section - flex-col on mobile, flex-row on md and up */}
            <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col flex-grow w-full gap-2 max-w-full md:max-w-xl">
                    <h1 className="text-primary font-primary">Balanço Mensal</h1>
                    <div className="flex flex-grow w-full p-2 shadow-lg rounded-lg bg-primaryBg border border-primaryBorder overflow-hidden">
                        <div className="w-full">
                            {isSubscribed && <MonthOverallChart month={currentMonth} />}
                            {!isSubscribed && <MonthOverallChartPaywall />}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-grow w-full gap-2 max-w-full md:max-w-xl">
                    <h1 className="text-primary font-primary">Despesas por Categoria</h1>
                    <div className="flex flex-grow w-full justify-center p-2 shadow-lg rounded-lg bg-primaryBg border border-primaryBorder overflow-hidden">
                        <div className="w-full">
                            {isSubscribed && <ExpenseByCategoryChart month={currentMonth} />}
                            {!isSubscribed && <ExpenseByCategoryChartPaywall />}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-grow w-full gap-2 max-w-full md:max-w-xl">
                    <h1 className="text-primary font-primary">Distribuição do Orçamento</h1>
                    {isSubscribed && <BudgetSummary month={currentMonth} />}
                    {!isSubscribed && <BudgetSummaryPaywall />}
                </div>
            </div>

            {/* Cards section - flex-col on mobile, flex-row on md and up */}
            <div className="flex flex-col xl:flex-row gap-4">
                <div className="flex flex-col flex-grow w-full gap-2 max-w-full xl:max-w-xl">
                    <h1 className="text-primary font-primary capitalize">Orçamento</h1>
                    {isSubscribed && <StackedSummary month={currentMonth} />}
                    {!isSubscribed && <StackedSummaryPaywall />}
                </div>
                <div className="flex flex-col flex-grow w-full gap-2 max-w-full xl:max-w-xl">
                    <h1 className="text-primary font-primary capitalize">Metas</h1>
                    {isSubscribed && <GoalSummary />}
                    {!isSubscribed && <GoalSummaryPaywall />}
                </div>
                <div className="flex flex-col flex-grow w-full gap-2 max-w-full xl:max-w-xl">
                    <h1 className="text-primary font-primary capitalize">Relatório da Inteligência Artificial</h1>
                    {isSubscribed && <AISummary />}
                    {!isSubscribed && <AISummaryPaywall />}
                </div>
            </div>
            
            {!isSubscribed && (
                <div className="mt-8 w-full">
                    <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl p-6 shadow-lg">
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div className="mb-4 md:mb-0 md:mr-6">
                                <h2 className="text-white text-xl font-bold mb-2">Desbloqueie recursos exclusivos!</h2>
                                <p className="text-blue-100">Tenha acesso a todos os recursos e transforme sua gestão financeira.</p>
                            </div>
                            <a 
                                href="https://cofrim.com.br" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="px-6 py-3 bg-white text-blue-700 font-bold rounded-lg hover:bg-blue-50 transition-all shadow-md flex items-center justify-center"
                            >
                                Ainda não é assinante? Clique aqui
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div >
    </>
}