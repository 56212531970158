import Card from '../Card';
import { JurosCompostosResult } from './ResultadoJurosCompostos';

type GraficoJurosCompostosProps = {
  resultado: JurosCompostosResult;
}

export const GraficoJurosCompostos = ({ resultado }: GraficoJurosCompostosProps) => {
  // Função para formatar valores monetários
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Mostrar apenas os últimos 5 anos para não sobrecarregar a tabela
  const dadosVisualizacao = resultado.projecao.length <= 5 
    ? resultado.projecao 
    : [
        resultado.projecao[0], 
        ...resultado.projecao.slice(-4)
      ];

  return (
    <Card>
      <h2 className="text-lg font-semibold text-gray-900 mb-2">Tabela de Evolução</h2>
      <p className="text-sm text-gray-600">
        Valores detalhados ao longo do período de investimento.
      </p>
      
      <div className="mt-4 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Período
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total Investido
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Juros Acumulados
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Montante Total
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {dadosVisualizacao.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  Ano {item.ano}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatCurrency(item.aportes_acumulados)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatCurrency(item.juros_acumulados)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatCurrency(item.capital_acumulado)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};