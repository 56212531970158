import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useAppContext } from '../context/AppContext';
import { Button } from './Button';
interface MonthOverallChartPaywallProps {
}

const MonthOverallChartPaywall: React.FC<MonthOverallChartPaywallProps> = ({ }) => {
    const { setPaymentModal } = useAppContext();
    const fakeData = [
        {
            "month": "Apr",
            "year": 2024,
            "income": 10000.0,
            "expenses": 8540,
            "balance": 0.0
        },
        {
            "month": "May",
            "year": 2024,
            "income": 12000.0,
            "expenses": 8930,
            "balance": 0.0
        },
        {
            "month": "Jun",
            "year": 2024,
            "income": 10000.0,
            "expenses": 7940.0,
            "balance": 0.0
        },
        {
            "month": "Jul",
            "year": 2024,
            "income": 10000.0,
            "expenses": 7900.0,
            "balance": 0.0
        },
        {
            "month": "Aug",
            "year": 2024,
            "income": 11540.0,
            "expenses": 8499.0,
            "balance": 0.0
        },
        {
            "month": "Sep",
            "year": 2024,
            "income": 11540.0,
            "expenses": 7853.0,
            "balance": 0.0
        },
        {
            "month": "Oct",
            "year": 2024,
            "income": 11540.0,
            "expenses": 7432.0,
            "balance": 0.0
        },
        {
            "month": "Nov",
            "year": 2024,
            "income": 11540.0,
            "expenses": 9543.0,
            "balance": 0.0
        },
        {
            "month": "Dec",
            "year": 2024,
            "income": 17857.0,
            "expenses": 12435.0,
            "balance": 0.0
        },
        {
            "month": "Jan",
            "year": 2025,
            "income": 11540.0,
            "expenses": 9450.0,
            "balance": 0.0
        },
        {
            "month": "Feb",
            "year": 2025,
            "income": 11540.0,
            "expenses": 8228.0,
            "balance": 0.0
        },
        {
            "month": "Mar",
            "year": 2025,
            "income": 12320.0,
            "expenses": 8990.0,
            "balance": 0
        }
    ]
    const [chartOptions, setChartOptions] = useState<any>(null);
    const [chartSeries, setChartSeries] = useState<any[]>([]);

    useEffect(() => {
        // Extract month names and data
        const categories = fakeData.map(item => item.month);
        const incomeData = fakeData.map(item => item.income);
        const expenseData = fakeData.map(item => item.expenses);

        // Create chart options
        setChartOptions({
            chart: {
                type: 'area',
                height: 350,
                toolbar: {
                    show: false,
                },
                stacked: false,
                redrawOnWindowResize: true,
                redrawOnParentResize: true,
                animations: {
                    enabled: true
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.4,
                    opacityFrom: 0.9,
                    opacityTo: 0.9,
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: '',
                },
                labels: {
                    formatter: (val: number) => `R$ ${val.toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}`,
                },
            },
            tooltip: {
                y: {
                    formatter: (val: number) => `R$ ${val.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`,
                },
                custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
                    const income = incomeData[dataPointIndex];
                    const expense = expenseData[dataPointIndex];
                    const balance = income - expense;
                    const color = balance >= 0 ? 'text-green-600' : 'text-red-600';

                    return `
                            <div class="p-2 bg-white shadow-md rounded-md">
                                <div class="font-medium">${categories[dataPointIndex]}</div>
                                <div class="text-green-600">Receita: R$ ${income.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</div>
                                <div class="text-red-600">Despesa: R$ ${expense.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</div>
                                <div class="pt-1 mt-1 border-t ${color}">
                                    Saldo: R$ ${balance.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                                </div>
                            </div>
                        `;
                }
            },
            colors: ['#10B981', '#EF4444'], // Income in green (emerald-500), Expense in red (red-500)
            legend: {
                position: 'top',
            }
        });

        // Create chart series
        setChartSeries([
            {
                name: 'Receita',
                data: incomeData,
            },
            {
                name: 'Despesa',
                data: expenseData,
            },
        ]);

    }, []);
    return (<div className="w-full overflow-hidden relative bg-white h-full items-center rounded-md">
        {/* Chart with reduced opacity */}
        <div className="opacity-20 pointer-events-none h-full">
            {chartOptions && chartSeries.length > 0 && (
                <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="area"
                    height={250}
                    width="100%"
                />
            )}
        </div>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center">
                <LockClosedIcon className="h-16 w-16 text-gray-500 mb-2" />
                <Button
                    title="Exclusivo para assinantes"
                    onClick={() => setPaymentModal(true)}
                />
            </div>
        </div>
    </div>
    );
};

export default MonthOverallChartPaywall;