import { CheckCircleIcon, TrophyIcon } from "@heroicons/react/24/solid";
import confetti from 'canvas-confetti';
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/Button";
import CourseProgress from "../components/CourseProgress";
import LoadingSpinner from "../components/Loading";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { useCourse } from "../hooks/useCourses";

function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

export default function CourseFinalized() {
    const navigate = useNavigate();
    const { user } = useAuthenticationContext();
    const { courseId } = useParams();

    if (!courseId) {
        navigate("/cursos");
    }

    const { data: course, isLoading } = useCourse(courseId ?? "");

    // Launch confetti effect when the component mounts
    useEffect(() => {
        if (course && !isLoading) {
            // Launch confetti
            const duration = 3 * 1000;
            const animationEnd = Date.now() + duration;
            const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

            const interval: any = setInterval(() => {
                const timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                const particleCount = 50 * (timeLeft / duration);

                // Launch confetti from both sides
                confetti({
                    ...defaults,
                    particleCount,
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
                });
                confetti({
                    ...defaults,
                    particleCount,
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
                });
            }, 250);
        }
    }, [course, isLoading]);

    if (!course || !courseId) {
        return <LoadingSpinner />;
    }

    return (
        <div className="flex flex-col gap-6 w-full max-w-6xl mx-auto">
            {/* Achievement Banner */}
            <div className="bg-gradient-to-r from-indigo-600 to-blue-500 rounded-2xl shadow-lg overflow-hidden">
                <div className="px-6 py-8 sm:p-10 sm:pb-6 flex flex-col md:flex-row items-center justify-between">
                    <div className="flex flex-col text-white max-w-xl">
                        <div className="flex items-center gap-2 mb-3">
                            <TrophyIcon className="h-8 w-8 text-yellow-300" />
                            <h2 className="text-2xl font-bold text-white">Parabéns {user?.first_name}!</h2>
                        </div>
                        <h3 className="text-3xl font-bold mb-2">{course.title}</h3>
                        <p className="opacity-90 mb-6">Você concluiu com sucesso este curso. Continue aprendendo com nossos outros conteúdos.</p>

                        <div className="flex flex-row items-center gap-4 mb-4">
                            <div className="bg-blue-900 bg-opacity-30 px-3 py-1 rounded-full flex items-center gap-1">
                                <CheckCircleIcon className="h-4 w-4 text-green-300" />
                                <span className="text-sm text-white">100% Concluído</span>
                            </div>
                        </div>

                        <CourseProgress
                            progress={{
                                completed: course.progress.total,
                                total: course.progress.total,
                                is_completed: true
                            }}
                            progressColor="bg-green-400"
                            textColor="text-white"
                        />
                    </div>

                    <div className="mt-6 md:mt-0 flex-shrink-0">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="h-32 w-32 rounded-full bg-white bg-opacity-20 animate-pulse"></div>
                            </div>
                            <img
                                src="/images/achievement.png"
                                alt="Troféu de curso finalizado"
                                className="w-40 h-40 relative z-10 drop-shadow-xl"
                            />
                        </div>
                    </div>
                </div>

                <div className="bg-indigo-900 bg-opacity-30 py-4 px-6 sm:px-10 flex flex-col sm:flex-row gap-4 justify-between items-center">
                    <p className="text-white text-sm">Continue sua jornada de aprendizado com mais conteúdos</p>
                    <div className="flex gap-3">
                        <Button
                            onClick={() => navigate(`/curso/${courseId}`)}
                            className="bg-white text-indigo-700 hover:bg-indigo-50"
                            title="Rever Curso"
                        />
                        <Button
                            onClick={() => navigate('/cursos')}
                            className="bg-indigo-500 hover:bg-indigo-600"
                            title="Ver Outros Cursos"
                        />
                    </div>
                </div>
            </div>

            {/* User Achievement Section */}
            {/* <div className="bg-white rounded-xl border border-gray-200 overflow-hidden mb-8">
                <div className="border-b border-gray-200 bg-gray-50 px-6 py-4">
                    <h3 className="text-lg font-semibold text-gray-800">Seu progresso</h3>
                </div>
                <div className="p-6 flex flex-col sm:flex-row gap-6 items-center sm:items-start">
                    <div className="flex items-center justify-center bg-indigo-50 rounded-xl p-4 h-32 w-32">
                        <CheckCircleIcon className="h-16 w-16 text-green-500" />
                    </div>
                    <div className="flex flex-col items-center sm:items-start">
                        <h4 className="text-xl font-semibold text-gray-800 mb-2">Excelente trabalho!</h4>
                        <p className="text-gray-600 mb-4 text-center sm:text-left">
                            Você concluiu com sucesso "{course.title}", adicionando uma nova
                            habilidade ao seu repertório. Continue praticando o que aprendeu!
                        </p>
                        <Button
                            onClick={() => navigate('/cursos')}
                            className="bg-indigo-600 hover:bg-indigo-700"
                            title="Explorar Mais Cursos"
                        />
                    </div>
                </div>
            </div> */}
        </div>
    );
}