import { format, parse } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useMonthBudgets } from "../hooks/useMonthBudget";
import { BudgetEmptyState } from "./EmptyTable";
import { BudgetChart } from "./charts/BudgetChart";

interface BudgetSummaryProps {
    month: string;
}

export default function BudgetSummary({ month }: BudgetSummaryProps) {
    // Use provided month or fallback to current date
    const currentMonth = month || format(new Date(), "yyyy-MM-dd");

    // Parse the month string to get year and month values for API call
    const parsedDate = parse(currentMonth, "yyyy-MM-dd", new Date());
    const monthNumber = parsedDate.getMonth() + 1; // JavaScript months are 0-indexed, but our API expects 1-indexed
    const year = parsedDate.getFullYear();

    const { data: monthBudgets, isLoading } = useMonthBudgets({
        month: monthNumber,
        year: year,
        page_size: 50,
        include_expenses: true
    });

    const navigate = useNavigate();

    // Transform the monthBudgets data to match the structure expected by BudgetChart
    const transformedCategories = monthBudgets?.results?.map(budget => ({
        id: budget.category,
        name: budget.category_name?.name || "Sem categoria",
        category_type: budget.category_name?.category_type || "expense",
        budget_amount: parseFloat(budget.budget_amount)
    })) || [];

    return (
        <>
            {!isLoading && (!monthBudgets?.results || monthBudgets.results.length === 0) && (
                <BudgetEmptyState />
            )}

            {(isLoading || (!isLoading && monthBudgets?.results && monthBudgets.results.length > 0)) && <div className="flex flex-col w-full bg-primaryBg flex-grow border border-secondaryBorder rounded-xl px-2 py-1 shadow-lg">
                <div className="flex h-full overflow-hidden bg-white w-full px-4 py-1 rounded-b-xl items-center justify-center">
                    {isLoading && (
                        <div className="animate-pulse w-full h-64 bg-gray-200 rounded-md flex items-center justify-center">
                            <p className="text-gray-500">Carregando dados do orçamento...</p>
                        </div>
                    )}

                    {!isLoading && monthBudgets?.results && monthBudgets.results.length > 0 && (
                        <BudgetChart categories={transformedCategories} />
                    )}
                </div>
            </div>}
        </>
    );
}