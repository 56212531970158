import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { useUser } from "../hooks/useUsers";
import { Course } from "../models/types";
import CourseProgress from "./CourseProgress";

type CourseCardProps = {
    course: Course;
}
export default function CourseCard({ course }: CourseCardProps) {
    const navigate = useNavigate();
    const { data: user } = useUser();
    const isAdmin = user?.is_admin === true;
    const isSubscriber = user?.plan && user?.plan !== "FREE";
    const isOsdPlan = user?.plan === "OSD";
    const isOsdCourse = course.plan === "OSD";
    const isFreeCourse = course.plan === "FREE";
    const isSubscriberCourse = course.plan === "SUBSCRIBER";
    const showLocked = (!isSubscriber && !isOsdPlan && !isFreeCourse) || (isOsdPlan && isSubscriberCourse);

    const { setPaymentModal } = useAppContext();

    const handleNavigate = () => {
        navigate(`/curso/${course.id}`)
    }

    const handleAction = () => {
        if ((!course.free && !isSubscriber) || (isSubscriberCourse && isOsdPlan)) return setPaymentModal(true);

        if (course.visible && (isOsdCourse || isFreeCourse)) return handleNavigate()

        if (course.visible && isSubscriberCourse && !isOsdPlan) return handleNavigate();
        return handleNavigate();
    }

    return <div onClick={() => handleAction()} className="flex flex-col w-60 gap-1">
        <div
            style={{ backgroundImage: course.image ? `url(${course.image})` : undefined }}
            className={`min-h-96 cursor-pointer md:h-96 flex w-60 justify-center shadow-lg border border-secondaryBorder rounded-lg bg-cover group bg-black`}>
            <div className="flex flex-grow w-full h-full rounded-lg items-end justify-center transition-all duration-500 hover:bg-[rgba(0,0,0,0.5)] relative">
                <div className="absolute bottom-0 rounded-b-lg w-full p-2 flex justify-center transition-all duration-500 bg-[rgba(0,0,0,0.5)] ease-in-out transform group-hover:translate-y-[-500%] group-hover:rounded-b-none">
                    <div className="flex flex-row gap-2 items-center">
                        {showLocked && <LockClosedIcon className="h-4 w-4 text-white" />}
                        <h1 className="text-text text-white font-text">{course.title}</h1>
                    </div>
                </div>
            </div>
        </div>
        <CourseProgress hideText progress={{
            completed: course.progress.completed,
            total: course.progress.total,
            is_completed: course.progress.is_completed
        }} />
    </div>
}