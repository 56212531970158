import { FolderIcon } from "@heroicons/react/24/outline";
import { Transaction, TransactionCategory } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";
import { EditIcon } from "./Button";

type GroupedTransactionProps = {
    category: TransactionCategory;
    transactions: Transaction[];
    total: number;
    onSelectTransaction: (transactionId: string) => void;
}

export const GroupedTransaction = ({
    category,
    transactions,
    total,
    onSelectTransaction,
}: GroupedTransactionProps) => {
    return (
        <div className="flex flex-col rounded-lg shadow-lg border border-primaryBorder bg-white overflow-hidden hover:shadow-xl transition-shadow duration-200">
            <div className="flex flex-col sm:flex-row p-4 bg-primaryBg border-b border-primaryBorder sm:items-center justify-between gap-3">
                <div className="flex items-center gap-2">
                    <FolderIcon className="h-5 w-5 text-blue-500 flex-shrink-0" />
                    <h3 className="text-primary font-primary truncate">{category.name}</h3>
                </div>
                <div className="flex flex-wrap gap-2 items-center justify-start sm:justify-end">
                    {category.budget_amount && (
                        <div className="px-3 py-1 bg-gray-100 rounded-full flex-shrink-0">
                            <div className="flex items-center gap-1">
                                <span className="text-xs text-gray-500">Orçamento:</span>
                                <span className="text-xs font-medium text-gray-700 whitespace-nowrap">R${formatBrazilianCurrence(category.budget_amount)}</span>
                            </div>
                        </div>
                    )}
                    <div className="px-3 py-1 bg-red-50 rounded-full flex-shrink-0">
                        <div className="flex items-center gap-1">
                            <span className="text-xs text-red-500">Total:</span>
                            <span className="text-xs font-medium text-red-600 whitespace-nowrap">R${formatBrazilianCurrence(total.toFixed(2))}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="flex flex-col w-full divide-y divide-gray-100">
                {transactions.map((transaction) => (
                    <div
                        key={transaction.id}
                        onClick={() => onSelectTransaction(transaction.id)}
                        className="flex px-4 py-3.5 flex-row justify-between w-full items-center hover:bg-blue-50 cursor-pointer transition-colors"
                    >
                        <div className="flex flex-row items-center gap-3 w-full max-w-[85%] overflow-hidden">
                            <div className="p-1.5 bg-blue-50 rounded-md">
                                <EditIcon className="text-blue-600 h-3.5 w-3.5 min-w-3.5 min-h-3.5" onClick={() => {}} />
                            </div>
                            <div className="flex flex-col">
                                <h4 className="text-sm text-primary truncate">{transaction.name}</h4>
                                <span className="text-xs text-secondaryText">Vencimento: Dia {transaction?.due_day}</span>
                            </div>
                        </div>
                        <span className="text-sm text-red-500">R${formatBrazilianCurrence(transaction.amount)}</span>
                    </div>
                ))}
                
                {transactions.length === 0 && (
                    <div className="p-6 text-center text-secondaryText">
                        Nenhuma transação nesta categoria
                    </div>
                )}
            </div>
        </div>
    );
}