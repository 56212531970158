import React from 'react';
import { Link } from 'react-router-dom';
import { 
  ArrowRightIcon, 
  CheckCircleIcon,
  ChartBarIcon,
  CalculatorIcon,
  UserGroupIcon,
  AcademicCapIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';

export default function LandingPage() {
  return (
    <div className="bg-primaryBg font-primary">
      {/* Navbar */}
      <nav className="bg-primaryBg border-b border-primaryBorder py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src="/images/logo.png" alt="Logo" className="h-10" />
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <a href="#funcionalidades" className="text-thirdText hover:text-blue-600 transition-colors duration-200 text-primary">Funcionalidades</a>
            <a href="#como-funciona" className="text-thirdText hover:text-blue-600 transition-colors duration-200 text-primary">Como Funciona</a>
            <a href="#precos" className="text-thirdText hover:text-blue-600 transition-colors duration-200 text-primary">Preços</a>
            <Link to="/login" className="px-5 py-2 bg-gray-100 text-thirdText rounded-lg hover:bg-gray-200 transition-colors duration-200 text-primary">Login</Link>
            <Link to="/signup" className="px-5 py-2 bg-blue-600 text-white rounded-lg hover:bg-hoverBg transition-colors duration-200 text-primary">Criar Conta</Link>
          </div>
          <div className="md:hidden">
            <button className="text-thirdText">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-50 to-indigo-50 py-16 md:py-24">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-10 md:mb-0">
            <h1 className="text-3xl md:text-5xl font-bold text-primaryText mb-6 leading-tight">
              Organize suas finanças e alcance seus objetivos
            </h1>
            <p className="text-lg text-thirdText mb-8 max-w-lg">
              Acompanhe despesas, crie metas financeiras e receba insights personalizados para melhorar sua saúde financeira.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/signup" className="px-8 py-3 bg-blue-600 text-white rounded-lg text-center font-medium hover:bg-hoverBg transition-colors duration-200 flex items-center justify-center">
                Começar Agora
                <ArrowRightIcon className="ml-2 h-5 w-5" />
              </Link>
              <a href="#como-funciona" className="px-8 py-3 bg-white text-blue-600 border border-blue-600 rounded-lg text-center font-medium hover:bg-blue-50 transition-colors duration-200">
                Como Funciona
              </a>
            </div>
          </div>
          <div className="md:w-1/2">
            <img src="/images/app/transacoes.png" alt="Dashboard" className="rounded-lg shadow-xl w-full" />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="funcionalidades" className="py-16 bg-primaryBg">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-primaryText mb-4">Funcionalidades principais</h2>
            <p className="text-lg text-thirdText max-w-xl mx-auto">
              Uma plataforma completa para gerenciar suas finanças pessoais, investimentos e objetivos financeiros.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-primaryBg p-6 rounded-xl shadow-sm border border-primaryBorder hover:shadow-md transition-shadow duration-200">
              <div className="bg-blue-100 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <ChartBarIcon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-primaryText mb-2">Controle de Orçamento</h3>
              <p className="text-thirdText">
                Visualize e organize suas despesas por categorias, entenda para onde vai seu dinheiro e crie orçamentos realistas.
              </p>
            </div>
            
            <div className="bg-primaryBg p-6 rounded-xl shadow-sm border border-primaryBorder hover:shadow-md transition-shadow duration-200">
              <div className="bg-green-100 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <CurrencyDollarIcon className="h-6 w-6 text-green-600" />
              </div>
              <h3 className="text-xl font-semibold text-primaryText mb-2">Metas Financeiras</h3>
              <p className="text-thirdText">
                Estabeleça metas claras para economizar, investir ou reduzir dívidas e acompanhe seu progresso com facilidade.
              </p>
            </div>
            
            <div className="bg-primaryBg p-6 rounded-xl shadow-sm border border-primaryBorder hover:shadow-md transition-shadow duration-200">
              <div className="bg-purple-100 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <CalculatorIcon className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold text-primaryText mb-2">Simuladores</h3>
              <p className="text-thirdText">
                Calcule juros compostos, independência financeira e mais com nossas ferramentas de simulação intuitivas.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* How it Works Section */}
      <div id="como-funciona" className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-primaryText mb-4">Como Funciona</h2>
            <p className="text-lg text-thirdText max-w-xl mx-auto">
              Transforme sua relação com o dinheiro em apenas 3 passos simples
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-blue-600 text-white text-2xl font-bold rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                1
              </div>
              <img src="/images/app/transacoes.png" alt="Cadastre suas transações" className="w-full h-48 object-contain mb-4 rounded-lg shadow-md" />
              <h3 className="text-xl font-semibold text-primaryText mb-2">Cadastre suas transações</h3>
              <p className="text-thirdText">
                Registre suas receitas e despesas com facilidade através da nossa interface intuitiva.
              </p>
            </div>
            
            <div className="text-center">
              <div className="bg-blue-600 text-white text-2xl font-bold rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                2
              </div>
              <img src="/images/app/metas.png" alt="Defina suas metas" className="w-full h-48 object-contain mb-4 rounded-lg shadow-md" />
              <h3 className="text-xl font-semibold text-primaryText mb-2">Defina suas metas</h3>
              <p className="text-thirdText">
                Estabeleça objetivos claros e acompanhe seu progresso em tempo real.
              </p>
            </div>
            
            <div className="text-center">
              <div className="bg-blue-600 text-white text-2xl font-bold rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                3
              </div>
              <img src="/images/app/orcamento.png" alt="Acompanhe seu orçamento" className="w-full h-48 object-contain mb-4 rounded-lg shadow-md" />
              <h3 className="text-xl font-semibold text-primaryText mb-2">Acompanhe seu orçamento</h3>
              <p className="text-thirdText">
                Visualize relatórios e insights sobre seus hábitos financeiros e tome melhores decisões.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="precos" className="py-16 bg-primaryBg">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-primaryText mb-4">Planos e Preços</h2>
            <p className="text-lg text-thirdText max-w-xl mx-auto">
              Escolha o plano ideal para suas necessidades financeiras
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {/* Plano Free */}
            <div className="bg-primaryBg rounded-xl shadow-md border border-primaryBorder overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold text-primaryText mb-2">Gratuito</h3>
                <div className="flex items-end mb-4">
                  <span className="text-4xl font-bold text-primaryText">R$0</span>
                  <span className="text-thirdText ml-1">/mês</span>
                </div>
                <p className="text-thirdText mb-6">
                  Perfeito para quem está começando a organizar suas finanças.
                </p>
                <Link to="/signup" className="block w-full py-2 px-4 bg-gray-100 text-thirdText rounded-lg text-center hover:bg-gray-200 transition-colors duration-200">
                  Começar Grátis
                </Link>
              </div>
              <div className="bg-gray-50 p-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Controle básico de despesas</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Acesso ao simulador de juros</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Conteúdo educativo básico</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Plano Premium */}
            <div className="bg-primaryBg rounded-xl shadow-xl border-2 border-blue-500 overflow-hidden transform scale-105 z-10">
              <div className="bg-blue-500 py-2">
                <p className="text-white text-center font-semibold">Mais Popular</p>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-primaryText mb-2">Premium</h3>
                <div className="flex items-end mb-4">
                  <span className="text-4xl font-bold text-primaryText">R$29</span>
                  <span className="text-thirdText ml-1">/mês</span>
                </div>
                <p className="text-thirdText mb-6">
                  Recursos completos para controle total das suas finanças.
                </p>
                <Link to="/signup" className="block w-full py-2 px-4 bg-blue-600 text-white rounded-lg text-center hover:bg-hoverBg transition-colors duration-200">
                  Assinar Agora
                </Link>
              </div>
              <div className="bg-gray-50 p-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Controle avançado de despesas</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Todos os simuladores financeiros</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Metas financeiras avançadas</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Acesso a todos os cursos</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Suporte prioritário</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Plano OSD */}
            <div className="bg-primaryBg rounded-xl shadow-md border border-primaryBorder overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold text-primaryText mb-2">OSD</h3>
                <div className="flex items-end mb-4">
                  <span className="text-4xl font-bold text-primaryText">R$99</span>
                  <span className="text-thirdText ml-1">/mês</span>
                </div>
                <p className="text-thirdText mb-6">
                  Para quem busca acompanhamento personalizado e exclusivo.
                </p>
                <Link to="/signup" className="block w-full py-2 px-4 bg-indigo-600 text-white rounded-lg text-center hover:bg-indigo-700 transition-colors duration-200">
                  Conheça o OSD
                </Link>
              </div>
              <div className="bg-gray-50 p-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Todas as funções do Premium</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Mentoria financeira exclusiva</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Comunidade de investidores</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                    <span className="text-thirdText">Relatórios personalizados</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-16 bg-blue-600">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Comece a transformar suas finanças hoje</h2>
          <p className="text-white text-lg mb-8 max-w-2xl mx-auto">
            Junte-se a milhares de pessoas que já estão no caminho para a saúde financeira e independência.
          </p>
          <Link to="/signup" className="inline-block px-8 py-3 bg-white text-blue-600 rounded-lg font-medium hover:bg-gray-100 transition-colors duration-200">
            Criar Minha Conta Grátis
          </Link>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-secondaryBg text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <img src="/images/white-logo.png" alt="Logo" className="h-8 mb-4" />
              <p className="text-gray-400 text-sm">
                Transformando sua relação com o dinheiro através de tecnologia e educação financeira.
              </p>
            </div>
            
            <div>
              <h4 className="text-lg font-semibold mb-4">Navegação</h4>
              <ul className="space-y-2">
                <li><a href="#funcionalidades" className="text-gray-400 hover:text-white transition-colors duration-200">Funcionalidades</a></li>
                <li><a href="#como-funciona" className="text-gray-400 hover:text-white transition-colors duration-200">Como Funciona</a></li>
                <li><a href="#precos" className="text-gray-400 hover:text-white transition-colors duration-200">Preços</a></li>
              </ul>
            </div>
            
            <div>
              <h4 className="text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><Link to="/politica-de-privacidade" className="text-gray-400 hover:text-white transition-colors duration-200">Política de Privacidade</Link></li>
                <li><Link to="/termos-de-uso" className="text-gray-400 hover:text-white transition-colors duration-200">Termos de Uso</Link></li>
              </ul>
            </div>
          </div>
          
          <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400 text-sm">
            <p>&copy; {new Date().getFullYear()} Mais Uma na Bolsa. Todos os direitos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}