import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useMonthlyBalance } from '../../hooks/useMonthlyBalance';

interface MonthOverallChartProps {
    month: string; // YYYY-MM-DD format
}

const MonthOverallChart: React.FC<MonthOverallChartProps> = ({ month }) => {
    const { data: monthlyBalanceData, isLoading, error } = useMonthlyBalance(month);
    const [chartOptions, setChartOptions] = useState<any>(null);
    const [chartSeries, setChartSeries] = useState<any[]>([]);

    useEffect(() => {
        if (monthlyBalanceData) {
            // Extract month names and data
            const categories = monthlyBalanceData.map(item => item.month);
            const incomeData = monthlyBalanceData.map(item => item.income);
            const expenseData = monthlyBalanceData.map(item => item.expenses);

            // Create chart options
            setChartOptions({
                chart: {
                    type: 'area',
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    stacked: false,
                    redrawOnWindowResize: true,
                    redrawOnParentResize: true,
                    animations: {
                        enabled: true
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical',
                        shadeIntensity: 0.4,
                        opacityFrom: 0.9,
                        opacityTo: 0.9,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent'],
                },
                xaxis: {
                    categories: categories,
                },
                yaxis: {
                    title: {
                        text: '',
                    },
                    labels: {
                        formatter: (val: number) => `R$ ${val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })}`,
                    },
                },
                tooltip: {
                    y: {
                        formatter: (val: number) => `R$ ${val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}`,
                    },
                    custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
                        const income = incomeData[dataPointIndex];
                        const expense = expenseData[dataPointIndex];
                        const balance = income - expense;
                        const color = balance >= 0 ? 'text-green-600' : 'text-red-600';

                        return `
                            <div class="p-2 bg-white shadow-md rounded-md">
                                <div class="font-medium">${categories[dataPointIndex]}</div>
                                <div class="text-green-600">Receita: R$ ${income.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}</div>
                                <div class="text-red-600">Despesa: R$ ${expense.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}</div>
                                <div class="pt-1 mt-1 border-t ${color}">
                                    Saldo: R$ ${balance.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                                </div>
                            </div>
                        `;
                    }
                },
                colors: ['#10B981', '#EF4444'], // Income in green (emerald-500), Expense in red (red-500)
                legend: {
                    position: 'top',
                }
            });

            // Create chart series
            setChartSeries([
                {
                    name: 'Receita',
                    data: incomeData,
                },
                {
                    name: 'Despesa',
                    data: expenseData,
                },
            ]);
        }
    }, [monthlyBalanceData]);

    if (isLoading) {
        return (
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <div className="animate-pulse w-full h-64 bg-gray-200 rounded-md flex items-center justify-center">
                    <p className="text-gray-500">Carregando dados do balanço mensal...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <div className="w-full h-64 bg-red-50 rounded-md flex items-center justify-center">
                    <p className="text-red-500">Erro ao carregar dados. Tente novamente mais tarde.</p>
                </div>
            </div>
        );
    }
    
    if (!monthlyBalanceData || monthlyBalanceData.length === 0) {
        return (
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <div className="w-full h-64 rounded-md flex flex-col items-center justify-center">
                    <svg
                        className="w-16 h-16 text-gray-300 mb-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <rect x="2" y="2" width="20" height="20" rx="2" ry="2"></rect>
                        <line x1="2" y1="12" x2="22" y2="12"></line>
                        <line x1="12" y1="2" x2="12" y2="22"></line>
                        <path d="M6 16l4-4"></path>
                        <path d="M6 8h4"></path>
                        <path d="M14 12h4"></path>
                        <path d="M14 16h4"></path>
                    </svg>
                    <h3 className="text-lg font-medium text-gray-700">Sem dados de balanço mensal</h3>
                    <p className="text-sm text-gray-500 mt-1 text-center max-w-xs">
                        Adicione transações para visualizar seu balanço mensal comparativo.
                    </p>
                </div>
            </div>
        );
    }

    return (<>
        {chartOptions && chartSeries.length > 0 && (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="area"
                height={250}
                width="100%"
            />
        )}
    </>
    );
};

export default MonthOverallChart;