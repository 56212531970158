import { useState, useEffect } from 'react';
import Card from '../Card';
import MainInput from '../MainInput';
import { Button } from '../Button';
import { GraficoJurosCompostos } from './GraficoJurosCompostos';
import { ResultadoJurosCompostos, JurosCompostosResult } from './ResultadoJurosCompostos';
import { GraficoEvolucaoJurosCompostos } from './GraficoEvolucaoJurosCompostos';

export type JurosCompostosData = {
  capital_inicial: number;
  aporte_mensal: number;
  taxa_juros: number;
  periodo_anos: number;
}

export const CalculadoraJurosCompostos = () => {
  // Estados para os valores da calculadora
  const [capitalInicial, setCapitalInicial] = useState<string>("1000");
  const [aporteMensal, setAporteMensal] = useState<string>("100");
  const [taxaJuros, setTaxaJuros] = useState<string>("10");
  const [periodoAnos, setPeriodoAnos] = useState<string>("10");
  
  // Estado para o resultado
  const [resultado, setResultado] = useState<JurosCompostosResult | null>(null);
  
  // Estado para erros de validação
  const [errors, setErrors] = useState({
    capitalInicial: "",
    aporteMensal: "",
    taxaJuros: "",
    periodoAnos: ""
  });

  // Função para formatar valores numéricos
  const formatNumber = (value: string): string => {
    // Remove qualquer caractere não numérico, exceto ponto decimal
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    // Converte para número
    const number = parseFloat(numericValue);
    
    // Retorna string vazia se não for um número válido
    if (isNaN(number)) return '';
    
    return number.toString();
  };

  // Função para validar o formulário
  const validateForm = (): boolean => {
    const newErrors = {
      capitalInicial: "",
      aporteMensal: "",
      taxaJuros: "",
      periodoAnos: ""
    };
    
    let isValid = true;

    // Validar capital inicial (pode ser zero)
    if (parseFloat(capitalInicial) < 0) {
      newErrors.capitalInicial = "O capital inicial não pode ser negativo";
      isValid = false;
    }

    // Validar aporte mensal (pode ser zero)
    if (parseFloat(aporteMensal) < 0) {
      newErrors.aporteMensal = "O aporte mensal não pode ser negativo";
      isValid = false;
    }

    // Validar taxa de juros
    if (parseFloat(taxaJuros) <= 0) {
      newErrors.taxaJuros = "A taxa de juros deve ser maior que zero";
      isValid = false;
    }

    // Validar período
    if (parseFloat(periodoAnos) <= 0) {
      newErrors.periodoAnos = "O período deve ser maior que zero";
      isValid = false;
    }

    if (parseFloat(periodoAnos) > 50) {
      newErrors.periodoAnos = "O período não deve exceder 50 anos";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Função para calcular juros compostos
  const calcularJurosCompostos = () => {
    if (!validateForm()) return;
    
    const capital = parseFloat(capitalInicial);
    const aporte = parseFloat(aporteMensal);
    const taxa = parseFloat(taxaJuros) / 100;
    const anos = parseFloat(periodoAnos);
    
    // Taxa mensal equivalente
    const taxaMensal = Math.pow(1 + taxa, 1/12) - 1;
    
    // Array para armazenar a projeção por ano
    const projecao = [];
    
    // Valores iniciais
    let montanteAtual = capital;
    let totalInvestido = capital;
    
    // Calculando mês a mês
    for (let mes = 1; mes <= anos * 12; mes++) {
      // Aplicar juros ao montante atual
      montanteAtual = montanteAtual * (1 + taxaMensal);
      
      // Adicionar o aporte mensal
      montanteAtual += aporte;
      totalInvestido += aporte;
      
      // Se completou um ano, armazenar na projeção
      if (mes % 12 === 0) {
        const anoAtual = mes / 12;
        projecao.push({
          ano: anoAtual,
          capital_acumulado: montanteAtual,
          aportes_acumulados: totalInvestido,
          juros_acumulados: montanteAtual - totalInvestido
        });
      }
    }
    
    // Resultado final
    const resultado: JurosCompostosResult = {
      montante_final: montanteAtual,
      total_investido: totalInvestido,
      juros_totais: montanteAtual - totalInvestido,
      projecao: projecao
    };
    
    setResultado(resultado);
  };

  // Resetar formulário quando a página for carregada
  useEffect(() => {
    setCapitalInicial("1000");
    setAporteMensal("100");
    setTaxaJuros("10");
    setPeriodoAnos("10");
    setResultado(null);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      {/* Primeira linha: Parâmetros e Resultado lado a lado */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          <h2 className="text-lg font-semibold text-gray-900">Parâmetros da Simulação</h2>
          <p className="text-sm text-gray-600">
            Preencha os dados para calcular o impacto dos juros compostos no seu investimento ao longo do tempo.
          </p>
          
          <div className="flex flex-col gap-4 mt-2">
            <MainInput
              title="Capital Inicial (R$)"
              type="text"
              placeholder="1000"
              value={capitalInicial}
              onChange={(e) => setCapitalInicial(formatNumber(e.target.value))}
              error={errors.capitalInicial}
            />
            
            <MainInput
              title="Aporte Mensal (R$)"
              type="text"
              placeholder="100"
              value={aporteMensal}
              onChange={(e) => setAporteMensal(formatNumber(e.target.value))}
              error={errors.aporteMensal}
            />
            
            <MainInput
              title="Taxa de Juros Anual (%)"
              type="text"
              placeholder="10"
              value={taxaJuros}
              onChange={(e) => setTaxaJuros(formatNumber(e.target.value))}
              error={errors.taxaJuros}
            />
            
            <MainInput
              title="Período (anos)"
              type="text"
              placeholder="10"
              value={periodoAnos}
              onChange={(e) => setPeriodoAnos(formatNumber(e.target.value))}
              error={errors.periodoAnos}
            />
            
            <Button 
              title="Calcular"
              onClick={calcularJurosCompostos}
              className="mt-4 w-full"
            />
          </div>
        </Card>
        
        {resultado && <ResultadoJurosCompostos resultado={resultado} />}
      </div>
      
      {/* Segunda linha: Evolução e Gráfico lado a lado */}
      {resultado && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <GraficoJurosCompostos resultado={resultado} />
          <GraficoEvolucaoJurosCompostos resultado={resultado} />
        </div>
      )}
    </div>
  );
};