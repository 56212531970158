import queryClient, { QueryKey } from "../hooks/state";
import { CreateGoal, Goal, GoalFilterParams, GoalTransaction } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/goals",
  retrieve: (id: string) => `/central/central/goals/${id}`,
  transactions: (id: string) => `/central/central/goals/${id}/transactions`,
  addTransaction: (id: string) => `/central/central/goals/${id}/add_transaction`,
  removeTransaction: (id: string, transactionId: string) => `/central/central/goals/${id}/remove_transaction?transaction_id=${transactionId}`,
};

export const getGoal = async (objectId: string) => {
  return httpGetAuthenticated<Goal>(endpoints.retrieve(objectId));
}

export const getGoals = async (params: GoalFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<Goal>>(endpoints.list(), {
    params,
  });
}

export const getGoalTransactions = async (goalId: string) => {
  const response = await httpGetAuthenticated<GoalTransaction[]>(endpoints.transactions(goalId));
  return response.data;
}

export const addGoalTransaction = async (goalId: string, data: { amount: number, description: string }) => {
  const response = await httpPostAuthenticated(endpoints.addTransaction(goalId), data);
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

export const removeGoalTransaction = async (goalId: string, transactionId: string) => {
  const response = await httpDeleteAuthenticated(endpoints.removeTransaction(goalId, transactionId));
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

export const createGoal = async (payload: CreateGoal) => {
  const response = await httpPostAuthenticated<Goal>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

export const updateGoal = async (id: string, payload: CreateGoal) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

export const deleteGoal = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.goals);
  return response.data;
}

