import { AdjustmentsHorizontalIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon, BanknotesIcon, ChartBarIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { addMonths, format, parseISO } from "date-fns";
import { useState } from "react";
import ImportTransactionsModal from "../components/modals/ImportTransactionsModal";
import MonthlyTransactionModal from "../components/modals/MonthlyTransactionCategoriesModal";
import { MonthlyBudgetFilters } from "../components/monthly-budget/Filters";
import BaseTable from "../components/monthly-budget/Table";
import ThreeDotsMenu from "../components/ThreeDotsMenu";
import { useMonthlyTransactions, useMonthlyTransactionsSummary } from "../hooks/useMonthlyTransactions";
import { MonthlyTransactionFetchParams } from "../models/types";
import { copyRecurrenceTransactions } from "../services/MonthlyTransactionService";
import { formatBrazilianCurrence } from "../utils/helper";
import { transactionColumns } from "./shared/TransactionsTable";


export default function TransactionsPage() {
    const [openCreateTransaction, setOpenCreateTransaction] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
    const [addExpense, setAddExpense] = useState(false);
    const [params, setParams] = useState<MonthlyTransactionFetchParams>({});
    const [month, setMonth] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const { data: summary } = useMonthlyTransactionsSummary({ month, ...params });
    const handleAdvanceMonth = () => {
        setMonth(format(addMonths(parseISO(month), 1), "yyyy-MM-dd"));
    }
    const handlePreviousMonth = () => {
        setMonth(format(addMonths(parseISO(month), -1), "yyyy-MM-dd"));
    }

    const threeDotsItems = [
        {
            name: 'Adicionar despesa', description: 'Adicione uma nova despesa para o mês atual', onClick: () => {
                setOpenCreateTransaction(true)
                setSelectedTransaction(null);
                setAddExpense(true);
            }, icon: PlusCircleIcon
        },
        {
            name: 'Adicionar receita', description: 'Adicione uma nova receita para o mês atual', onClick: () => {
                setOpenCreateTransaction(true)
                setSelectedTransaction(null);
                setAddExpense(false);
            }, icon: BanknotesIcon
        },
        {
            name: 'Importar despesas fixas', description: 'Importe todas suas despesas fixas para o mês atual',
            onClick: () => {
                copyRecurrenceTransactions({ month });
            }, icon: ClipboardDocumentListIcon
        },
        {
            name: 'Importar arquivo OFX', description: 'Importe suas transações a partir de um arquivo OFX',
            onClick: () => setOpenImport(true), icon: ClipboardDocumentCheckIcon
        }
    ]

    return (
        <div className="flex flex-col w-full gap-6 justify-start">
            <MonthlyTransactionModal
                isExpense={addExpense}
                transactionId={selectedTransaction}
                open={openCreateTransaction}
                onClose={() => {
                    setOpenCreateTransaction(false)
                    setSelectedTransaction(null);
                    setAddExpense(false);
                }}
            />
            <ImportTransactionsModal open={openImport} onClose={() => setOpenImport(false)} />

            {/* Page header */}
            <div className="flex flex-col w-full gap-2">
                <div className="flex flex-row items-start justify-between">
                    <h1 className="text-primary font-primary capitalize text-xl truncate">Fluxo de Caixa</h1>
                    <div style={{ position: 'relative' }}>
                        <ThreeDotsMenu className="flex-shrink-0" items={threeDotsItems} />
                    </div>
                </div>
                <p className="text-sm text-secondaryText font-text mt-1">
                    Aqui você consegue organizar o fluxo de caixa do mês, mapeie suas despesas e receitas, controle o que já foi pago e o que ainda falta pagar.
                </p>
            </div>

            {/* Filters and period selector */}
            <div className="w-full">
                <div className="rounded-lg shadow-lg border border-primaryBorder" style={{ overflow: 'visible' }}>
                    <div className="bg-primaryBg p-4 border-b border-primaryBorder flex justify-between items-center">
                        <h3 className="text-primary font-primary truncate">Filtros</h3>
                        <AdjustmentsHorizontalIcon className="h-5 w-5 text-blue-600 flex-shrink-0" />
                    </div>
                    <div className="p-4 bg-white" style={{ position: 'relative', zIndex: 20 }}>
                        <div className="flex flex-col sm:flex-row gap-4 w-full">
                            {/* Period selector */}
                            <div className="sm:w-64 flex-shrink-0">
                                <label className="block text-xs text-secondaryText font-text mb-2">Período</label>
                                <div className="flex flex-row items-center gap-2 p-2 border border-primaryBorder rounded-md">
                                    <ArrowLeftCircleIcon onClick={() => handlePreviousMonth()} className="h-5 w-5 text-blue-600 cursor-pointer flex-shrink-0" />
                                    <h4 className="text-lg font-primary text-primaryText whitespace-nowrap flex-grow text-center">{format(parseISO(month), "MM/yyyy")}</h4>
                                    <ArrowRightCircleIcon onClick={() => handleAdvanceMonth()} className="h-5 w-5 text-blue-600 cursor-pointer flex-shrink-0" />
                                </div>
                            </div>

                            {/* Other filters */}
                            <div className="flex-grow relative">
                                <MonthlyBudgetFilters filters={params} setFilters={setParams} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Summary cards */}
            {summary && (
                <div className="flex flex-col sm:flex-row gap-4">
                    <div className="flex-1 rounded-lg shadow-lg border bg-primaryBg border-primaryBorder overflow-hidden">
                        <div className="bg-primaryBg py-3 px-4 border-b border-primaryBorder flex justify-between items-center">
                            <h3 className="text-primary font-primary truncate">Receitas</h3>
                            <BanknotesIcon className="h-5 w-5 text-green-600 flex-shrink-0" />
                        </div>
                        <div className="py-3 px-4 bg-white">
                            <div className="flex flex-row items-center justify-between">
                                <p className="text-xs text-secondaryText">Total de receitas:</p>
                                <p className="text-green-600 text-lg font-semibold whitespace-nowrap">R$ {formatBrazilianCurrence(summary.income_transactions_sum)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 rounded-lg shadow-lg border border-primaryBorder overflow-hidden">
                        <div className="bg-primaryBg py-3 px-4 border-b border-primaryBorder flex justify-between items-center">
                            <h3 className="text-primary font-primary truncate">Despesas</h3>
                            <ChartBarIcon className="h-5 w-5 text-red-500 flex-shrink-0" />
                        </div>
                        <div className="py-3 px-4 bg-white">
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                    <span className="text-xs font-medium text-primaryText">Total:</span>
                                    <span className="text-red-500 text-base font-semibold whitespace-nowrap">R$ {formatBrazilianCurrence(summary.total_transactions_sum)}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row flex-wrap gap-2 text-xs mt-1">
                                    <div className="flex items-center justify-between sm:justify-start gap-1 sm:flex-1">
                                        <span className="text-gray-600">Pendente:</span>
                                        <span className="font-medium text-gray-600">R$ {formatBrazilianCurrence(summary.pending_transactions_sum)}</span>
                                    </div>
                                    <div className="flex items-center justify-between sm:justify-start gap-1 sm:flex-1">
                                        <span className="text-red-500">Vencido:</span>
                                        <span className="font-medium text-red-500">R$ {formatBrazilianCurrence(summary.due_transactions_sum)}</span>
                                    </div>
                                    <div className="flex items-center justify-between sm:justify-start gap-1 sm:flex-1">
                                        <span className="text-green-600">Pago:</span>
                                        <span className="font-medium text-green-600">R$ {formatBrazilianCurrence(summary.paid_transactions_sum)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Transactions table */}
            <BaseTable
                useGetData={useMonthlyTransactions}
                onRowClick={(row) => {
                    setSelectedTransaction(row.id)
                    setOpenCreateTransaction(true);
                }}
                columns={transactionColumns}
                params={{
                    ...params,
                    month
                }}
            />
        </div>
    );
}