import { useNavigate } from "react-router-dom";
import { useGoals } from "../hooks/useGoals";
import { formatToBrazilianCurrency } from "../utils/handleMoneyChange";
import CourseProgress from "./CourseProgress";
import { GoalEmptyState } from "./EmptyTable";

export default function GoalSummary() {
    const { data: goals } = useGoals({ page_size: 4 });
    const navigate = useNavigate();
    const hasMoreGoals = !!goals?.next;
    // If there are no goals, show the empty state
    if (goals?.results?.length === 0) {
        return <GoalEmptyState />;
    }

    return <div className="flex flex-col w-full xl:min-h-60 bg-primaryBg border border-secondaryBorder rounded-xl px-2 py-1 shadow-lg overflow-hidden">
        {goals?.results?.map((goal, idx) => <div key={goal.id} className={`flex py-1 flex-col ${idx + 1 !== goals?.results?.length ? "border-b border-gray-300" : ""}`}>
            <div className="flex flex-row gap-2 justify-between items-center">
                <h4 className="text-sm leading-7 font-light truncate">{goal.name}</h4>
                <span className="text-sm leading-7 font-light">R${formatToBrazilianCurrency(`${goal.goal_amount}`)}</span>
            </div>
            <CourseProgress progress={{
                completed: goal.amount,
                total: goal.goal_amount,
                is_completed: goal.progress.is_completed,
            }} />
        </div>)}
        {hasMoreGoals && <div className="flex justify-center p-2">
            <h4 onClick={() => navigate("/metas")} className="text-sm cursor-pointer hover:underline text-blue-600">Ver todas {goals?.count} metas</h4>
        </div>}
    </div>
}