import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useTransactionCategory, useTransactionCategoryTypes } from "../../hooks/useTransactionCategories";
import { TransactionCategoryType } from "../../models/types";
import { createTransactionCategory, deleteTransactionCategory, updateTransactionCategory } from "../../services/TransactionCategoriesService";
import { transactionCategoriesTypes } from "../../utils/helper";
import { Button } from "../Button";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import ConfirmationModal from "./ConfirmationModal";
import SidebarModal from "./SidebarModal";

type TransactionCategoryModalProps = {
    open: boolean;
    onClose: () => void;
    categoryId: string | null;
}

export default function TransactionCategoryModal({ open, onClose, categoryId }: TransactionCategoryModalProps) {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [type, setType] = useState<TransactionCategoryType | null>(null);
    const [typeError, setTypeError] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const { data: category, isLoading } = useTransactionCategory(categoryId);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useAppContext();
    useEffect(() => {
        if (category) {
            setName(category.name);
            setType(transactionCategoriesTypes.find((id) => id.id === category.category_type) ?? null);
        } else {
            setName("");
        }
    }, [category, open])

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let hasError = false
            if (!name || (name && name === "")) {
                setNameError(true);
                hasError = true;
            } else {
                setNameError(false);
            }
            if (!type) {
                setTypeError(true);
                hasError = true;
            } else {
                setTypeError(false);
            }
            if (hasError) return;
            if (categoryId) {
                await updateTransactionCategory(categoryId, { name, category_type: type!.id });
            } else {
                await createTransactionCategory({ name, category_type: type!.id });
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Categoria ${categoryId ? "editada" : "adicionada"} com sucesso`,
                title: `Distribuição de Orçamento`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${categoryId ? "editar" : "adicionar"} a configuração, tente novamente`,
                title: `Distribuição de Orçamento`
            })
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async () => {
        try {
            setLoading(true);
            if (categoryId) {
                await deleteTransactionCategory(categoryId);
                onClose();
                setNotification({
                    message: "Categoria excluida com sucesso",
                    title: "Sucesso",
                    type: "success"
                })
            }
        } catch (e) {
            setNotification({
                message: "Erro ao excluir categoria",
                title: "Erro",
                type: "error"
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <SidebarModal
            title={`${categoryId ? "Editar" : "Adicionar"} Categoria`}
            onDelete={categoryId ? () => setOpenConfirmation(true) : undefined}
            action={<Button
                disabled={loading}
                loading={loading}
                onClick={() => handleSubmit()}
                className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                title={categoryId ? "Salvar" : "Adicionar"}
            />}
            open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <h4 className="text-xs font-semibold text-gray-700">Nome</h4>
                <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => {
                        setNameError(false);
                        setName(e.target.value)
                    }}
                    className={`py-2 px-4 rounded-2xl text-xs cursor-pointer ${nameError ? "border-red-600" : ""}`}
                />
                {nameError && <h4 className="px-4 text-xs font-light text-red-600">O nome é obrigatório</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Tipo</h4>
                <CustomSelectDropdown<TransactionCategoryType, any>
                    params={{}}
                    placeholder="Selecione um tipo"
                    useGetOptions={useTransactionCategoryTypes}
                    nameKey="name" onChange={(v) => { setType(v); setTypeError(false) }} selected={type}
                />
                {typeError && <h4 className="px-4 text-xs font-light text-red-600">O tipo é obrigatório</h4>}
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => {
                        handleDelete()
                        setOpenConfirmation(false)
                    }}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Categoria"
                    message={`Deseja realmente excluir a categoria ${category?.name}? Todas as transações vinculadas a essa categoria serão atualizadas.`} />
            </div>
        </SidebarModal>
    )
}
