import AIReportReady from "./alberto/ReportReady";
import PaywallButton from "./PaywallButton";

export default function AISummaryPaywall() {
    return <div className="flex flex-col w-full flex-grow bg-primaryBg border border-secondaryBorder rounded-xl px-2 py-1 shadow-lg overflow-hidden">
        <div className="flex opacity-20">
            <AIReportReady disabled={true} />
        </div>
        <PaywallButton className="mt-[-350px] md:mt-[-245px]" />
    </div>
}