import { useState } from "react";
import BaseCard from "../components/BaseCard";
import { Button } from "../components/Button";
import GoalCard from "../components/GoalCard";
import GoalCreationModal from "../components/modals/GoalCreationModal";
import { GoalCardListSkeletonLoader } from "../components/skeleton/GoalCardSkeleton";
import { useGoals } from "../hooks/useGoals";

export default function GoalsPage() {
    const { data: goals, isLoading, isFetching } = useGoals({});
    const [openCreateGoal, setOpenCreateGoal] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState<string | null>(null);

    return (
        <div className="flex flex-col w-full gap-4 justify-start">
            {/* Header section */}
            <div className="flex flex-col w-full gap-2">
                <h1 className="text-primary font-primary">Metas</h1>
            </div>

            {/* Content section */}
            <GoalCreationModal
                open={openCreateGoal}
                onClose={() => {
                    setOpenCreateGoal(false)
                    setSelectedGoal(null);
                }}
                goalId={selectedGoal}
            />

            {!isLoading && !isFetching && (
                <div className="flex flex-row flex-wrap w-full gap-6">
                    {goals?.results?.map((goal) => (
                        <GoalCard
                            key={goal.id}
                            goal={goal}
                            onEdit={(goalId: string) => {
                                setSelectedGoal(goalId);
                                setOpenCreateGoal(true);
                            }}
                        />
                    ))}

                    <BaseCard
                        className="max-w-full sm:max-w-80 min-w-72 border-dashed border-gray-400 py-4 shadow-md hover:shadow-lg transition-shadow"
                        content={
                            <div className="flex px-4 flex-col items-center justify-center gap-1 rounded-t-lg">
                                <h3 className="font-primary text-sm font-medium text-primaryText">Nova meta</h3>
                                <h3 className="text-sm text-center text-secondaryText mb-3 font-text">Adicione uma nova meta para acompanhamento</h3>
                                <Button
                                    onClick={() => {
                                        setSelectedGoal(null);
                                        setOpenCreateGoal(true);
                                    }}
                                    title="Criar Meta"
                                />
                            </div>
                        }
                        icon={<></>}
                        title="Nova Meta"
                        hideTitle={true}
                    />
                </div>
            )}

            {(isLoading || isFetching) && <GoalCardListSkeletonLoader />}
        </div>
    );
}