import axios from "axios";
import { getCookie } from "../utils/cookies";

const axiosUnauthenticated = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

const axiosAuthenticated = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

axiosAuthenticated.interceptors.request.use((request: any) => {
  // get token from cookies and add to request
  const token = getCookie("token");
  if (token && token !== "") {
    request.headers.setAuthorization(`Bearer ${token}`);
  }
  return request;
});

axiosAuthenticated.interceptors.response.use((request: any) => request, (error: any) => {
  const currentPath = window.location.pathname;
  if (error.response.status === 401) {
    if (currentPath !== "/login") {
      window.location.href = "/login";
    }
  }
  if (error.response.status === 403) { // move to 402
    // window.location.href = "/inicio";
  }
  return Promise.reject(error);
});

export const httpGetUnauthenticated = <T = any>(endpoint: string, data: any = null) => {
  return axiosUnauthenticated.get<T>(endpoint, data);
};

export const httpPostUnauthenticated = <T = any>(
  endpoint: string,
  data: any = null,
  config: any = undefined
) => {
  return axiosAuthenticated.post<T>(endpoint, data, config);
};

export const httpGetAuthenticated = <T = any>(endpoint: string, data: any = null) => {
  return axiosAuthenticated.get<T>(endpoint, data);
};

export const httpPostAuthenticated = <T = any>(
  endpoint: string,
  data: any = null,
  config: any = undefined
) => {
  return axiosAuthenticated.post<T>(endpoint, data, config);
};

export const httpPatchAuthenticated = (endpoint: string, data: any = null, config: any = undefined) => {
  return axiosAuthenticated.patch(endpoint, data, config);
};

export const httpDeleteAuthenticated = (endpoint: string, data: any = null) => {
  return axiosAuthenticated.delete(endpoint, data);
};