import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { APIResponse } from '../../models/types';

type CustomSelectDropdownProps<T extends { id: string }, P> = {
    useGetOptions: APIResponse<T, P>;
    selected: T | null;
    nameKey: keyof T;
    params: P;
    secondComponent?: (option: T) => React.ReactNode;
    onChange: (value: T | null) => void;
    label?: string;
    createComponent?: React.ReactNode;
    onAdd?: () => void;
    onAddLabel?: string;
    className?: string;
    disabled?: boolean;
    editComponent?: (option: T) => React.ReactNode;
    placeholder?: string;
}

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function CustomSelectDropdown<T extends { id: string }, P>({
    useGetOptions,
    editComponent,
    selected,
    onChange,
    nameKey,
    label,
    secondComponent,
    createComponent,
    className,
    params,
    disabled,
    placeholder = "Selecione uma categoria"
}: CustomSelectDropdownProps<T, P>) {
    const { data } = useGetOptions(params);
    const handleClear = () => {
        onChange(null)
    };
    return (
        <Listbox disabled={disabled} value={selected} onChange={onChange}>
            {({ open }) => (
                <div className={`flex flex-col gap-1 flex-wrap flex-grow ${className}`}>
                    {label && <Label className="block text-sm font-medium leading-6 text-gray-900">{label}</Label>}
                    <div className="relative">
                        <ListboxButton className="flex flex-row  justify-between items-center w-full cursor-default rounded-2xl bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 focus:ring-blue-700 focus:outline-none focus:ring-2 sm:text-xs sm:leading-6">
                            <span className="flex items-center gap-2">
                                <span className="text-medium font-small ml-3 block truncate">{`${selected ? selected[nameKey] : placeholder}`}</span>
                                {selected && secondComponent && secondComponent(selected)}
                            </span>
                            <div className="flex flex-row gap-1 items-center">
                                {selected && <span className="inset-y-0 right-0 flex items-center">
                                    <XMarkIcon onClick={(e) => { e.stopPropagation(); handleClear(); }} className="cursor-pointer h-5 w-5 text-gray-400 hover:bg-gray-100 hover:rounded-md" aria-hidden="true" />
                                </span>}
                                <span className="pointer-events-none inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </div>
                        </ListboxButton>

                        <ListboxOptions
                            className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-xs"
                            style={{ position: 'absolute' }}
                        >
                            {data && data?.results?.map((obj) => (
                                <ListboxOption
                                    key={obj.id}
                                    className={({ focus }) =>
                                        classNames(
                                            focus ? 'bg-indigo-50' : '',
                                            !focus ? 'text-gray-900' : '',
                                            'relative cursor-default select-none py-2 pl-3 pr-9 truncate',
                                        )
                                    }
                                    value={obj}
                                >
                                    {({ selected, focus }) => (
                                        <>
                                            <div className="flex items-center justify-between gap-2">
                                                <div className="flex items-center gap-2">
                                                    {secondComponent && secondComponent(obj)}
                                                    <span className={classNames('font-small text-medium ml-3 block truncate')}>
                                                        {`${obj[nameKey]}`}
                                                    </span>
                                                </div>
                                                {editComponent && editComponent(obj)}
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        focus ? 'text-white' : 'text-indigo-600',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </ListboxOption>
                            ))}
                            {createComponent}
                        </ListboxOptions>
                    </div>
                </div>
            )}
        </Listbox>
    )
}
