import { ChartBarIcon, ExclamationCircleIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { CategoryBadge } from "../components/Badge";
import { Button } from "../components/Button";
import { CustomInput } from "../components/CustomInput";
import { GroupedTransaction } from "../components/GroupedTransaction";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import SidebarModal from "../components/modals/SidebarModal";
import CustomSelectDropdown from "../components/monthly-budget/CustomSelectDropdown";
import { useBudgetSettings } from "../hooks/useBudgetSettings";
import { useTransactionCategories } from "../hooks/useTransactionCategories";
import { useTransaction, useTransactions } from "../hooks/useTransactions";
import { Transaction, TransactionCategory, TransactionCategoryFilterParams } from "../models/types";
import { createTransaction, deleteTransaction, updateTransaction } from "../services/TransactionService";
import { handleMoneyChange } from "../utils/handleMoneyChange";
import { formatBrazilianCurrence } from "../utils/helper";

export default function RecurrenceTransactionPage() {
    const [groupedTransactions, setGroupedTransactions] = useState<{ category: TransactionCategory, transactions: Transaction[]; total: number; }[]>([]);
    const [openCreateTransaction, setOpenCreateTransaction] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
    const { data: settings } = useBudgetSettings();
    const { data: transactions } = useTransactions({
        page_size: 50,
    });

    // Transaction form states
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [name, setName] = useState<string>("");
    const [amount, setAmount] = useState<string>("0");
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [amountError, setAmountError] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [categoryError, setCategoryError] = useState<string>("");
    const [dueDay, setDueDay] = useState<number>(1);
    const [dueDayError, setDueDayError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    // Get transaction details when editing
    const { data: transaction } = useTransaction(selectedTransaction);

    useEffect(() => {
        if (transactions) {
            const result = Object.values(
                transactions?.results?.reduce<Record<string, { category: TransactionCategory; transactions: Transaction[]; total: number; }>>((acc, transaction) => {
                    const key = transaction?.category_name?.id ?? "Sem categoria"; // Group by category_name.id
                    if (!acc[key]) {
                        acc[key] = {
                            category: transaction.category_name ?? {
                                name: "Sem categoria",
                                id: "Sem categoria",
                                category_type: "expense",
                                budget_amount: null,
                            },
                            transactions: [],
                            total: 0,
                        };
                    }
                    acc[key].transactions.push(transaction);
                    acc[key].total += Number(transaction.amount);
                    return acc;
                }, {})
            );
            setGroupedTransactions(result);
        } else {
            setGroupedTransactions([]);
        }
    }, [transactions])

    // Set form values when editing a transaction
    useEffect(() => {
        if (selectedTransaction && transaction) {
            setName(transaction.name)
            setAmount(`${transaction.amount}`.replace(".", ","));
            setSelectedCategory(transaction.category_name)
            setDueDay(transaction.due_day)
        } else {
            setName("")
            setAmount("0");
            setSelectedCategory(null)
            setDueDay(1);
        }
    }, [transaction, openCreateTransaction])

    // Handle changing due day
    const handleChangeDueDay = (val: string) => {
        // Remove all characters that are not numbers
        const value = val.replace(/\D/g, '');
        if (value.length > 2) {
            return;
        }
        const day = Number(value);
        setDueDay(day);
        if (day < 1 || day > 31) {
            setDueDayError("Dia inválido, insira um dia entre 1 e 31");
        } else {
            setDueDayError("");
        }
    }

    // Handle creating or updating a transaction
    const handleSaveTransaction = async () => {
        try {
            setLoading(true);
            if (selectedCategory === null) {
                setCategoryError("Selecione uma categoria");
                setLoading(false);
                return;
            } else {
                setCategoryError("");
            }
            if (name === "") {
                setNameError("Nome da transação é obrigatório");
                setLoading(false);
                return;
            } else {
                setNameError("");
            }
            if (dueDay < 1 || dueDay > 31) {
                setDueDayError("Dia inválido, insira um dia entre 1 e 31");
                setLoading(false);
                return;
            } else {
                setDueDayError("");
            }
            if (amount === "") {
                setAmountError("Valor da transação é obrigatório");
                setLoading(false);
                return;
            } else {
                setAmountError("");
            }

            if (selectedTransaction) {
                await updateTransaction(selectedTransaction, {
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    due_day: dueDay
                })
            } else {
                await createTransaction({
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    due_day: dueDay
                })
            }

            setLoading(false);
            closeTransactionModal();
        } catch (error) {
            console.error("Error saving transaction:", error);
            setLoading(false);
        }
    }

    // Handle deleting a transaction
    const handleDeleteTransaction = async () => {
        if (!selectedTransaction) return;
        try {
            setLoading(true);
            await deleteTransaction(selectedTransaction);
            setLoading(false);
            setOpenConfirmation(false);
            closeTransactionModal();
        } catch (error) {
            console.error("Error deleting transaction:", error);
            setLoading(false);
        }
    }

    // Close transaction modal and reset state
    const closeTransactionModal = () => {
        setOpenCreateTransaction(false);
        setSelectedTransaction(null);
        setName("");
        setAmount("0");
        setSelectedCategory(null);
        setDueDay(1);
        setNameError("");
        setAmountError("");
        setCategoryError("");
        setDueDayError("");
    }

    // Calculate total expenses
    const totalExpenses = groupedTransactions.reduce((acc, group) => acc + group.total, 0);

    // Calculate recommended emergency fund (6x total expenses rounded)
    const emergencyFund = Math.round(totalExpenses * 6);

    // Find biggest expense
    const biggestExpenseCategory = groupedTransactions.length > 0
        ? groupedTransactions.reduce((acc, group) => acc.total > group.total ? acc : group)
        : null;

    return (
        <div className="flex flex-col w-full gap-6 justify-start">
            {/* Transaction Sidebar Modal */}
            <SidebarModal
                title={selectedTransaction ? "Editar Despesa Fixa" : "Nova Despesa Fixa"}
                open={openCreateTransaction}
                onClose={() => { closeTransactionModal() }}
                onDelete={selectedTransaction ? () => {
                    setOpenConfirmation(true)
                    setOpenCreateTransaction(false);
                } : undefined}
                action={
                    <Button
                        disabled={loading}
                        loading={loading}
                        onClick={handleSaveTransaction}
                        className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                        title={selectedTransaction ? "Salvar Alterações" : "Adicionar Despesa"}
                    />
                }
            >
                <div className="flex flex-col gap-4 pt-4">
                    <div className="flex flex-col gap-1">
                        <h4 className="text-sm font-primary text-primary">Categoria</h4>
                        <p className="text-xs text-secondaryText font-text">Selecione a categoria que melhor descreve essa despesa</p>
                        <CustomSelectDropdown<TransactionCategory, TransactionCategoryFilterParams>
                            params={{ page_size: 100, category_type: "expense" }}
                            secondComponent={(category) => <CategoryBadge category={category} />}
                            useGetOptions={useTransactionCategories}
                            nameKey="name"
                            onChange={setSelectedCategory}
                            selected={selectedCategory}
                        />
                        {categoryError && <p className="text-xs text-red-500 mt-1">{categoryError}</p>}
                    </div>

                    <div className="flex flex-col gap-1">
                        <h4 className="text-sm font-primary text-primary">Nome da despesa</h4>
                        <p className="text-xs text-secondaryText font-text">Informe um nome para identificar a despesa</p>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setNameError("");
                            }}
                            className={`py-2 px-3 text-sm rounded-lg border ${nameError ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="Ex: Aluguel, Netflix, Academia..."
                        />
                        {nameError && <p className="text-xs text-red-500 mt-1">{nameError}</p>}
                    </div>

                    <div className="flex flex-col gap-1">
                        <h4 className="text-sm font-primary text-primary">Dia do vencimento</h4>
                        <p className="text-xs text-secondaryText font-text">Informe o dia de vencimento (1 a 31)</p>
                        <input
                            type="text"
                            value={dueDay}
                            onChange={(e) => handleChangeDueDay(e.target.value)}
                            className={`py-2 px-3 text-sm rounded-lg border ${dueDayError ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="Dia do vencimento"
                        />
                        {dueDayError && <p className="text-xs text-red-500 mt-1">{dueDayError}</p>}
                    </div>

                    <div className="flex flex-col gap-1">
                        <h4 className="text-sm font-primary text-primary">Valor</h4>
                        <p className="text-xs text-secondaryText font-text">Informe o valor da despesa</p>
                        <CustomInput
                            error={amountError}
                            onChange={(e) => {
                                handleMoneyChange(e, setAmount);
                                setAmountError("");
                            }}
                            value={amount}
                            placeholder="0,00"
                            fixedPlaceholder="R$"
                            id="valor"
                            type="text"
                        />
                        {amountError && <p className="text-xs text-red-500 mt-1">{amountError}</p>}
                    </div>
                </div>
            </SidebarModal>

            {/* Confirmation Modal for deletion */}
            <ConfirmationModal
                open={openConfirmation}
                onClose={() => {
                    setOpenConfirmation(false)
                    setOpenCreateTransaction(true);
                }}
                onConfirm={() => handleDeleteTransaction()}
                onCancel={() => {
                    setOpenConfirmation(false)
                    setOpenCreateTransaction(true);
                }}
                title="Excluir Despesa"
                message={`Deseja realmente excluir a despesa ${transaction?.name}?`}
            />

            {/* Page header */}
            <div className="flex flex-col w-full gap-2">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                    <div className="max-w-full">
                        <h1 className="text-primary font-primary capitalize text-xl truncate">Despesas Fixas</h1>
                        <p className="text-sm text-secondaryText font-text mt-1 line-clamp-2 sm:line-clamp-none">
                            Organize e controle suas despesas fixas de forma eficiente. Aqui você pode gerenciar todas as suas contas mensais.
                        </p>
                    </div>
                    <Button
                        title="+ Nova Despesa"
                        onClick={() => setOpenCreateTransaction(true)}
                        className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white flex-shrink-0 w-full sm:w-auto"
                    />
                </div>
            </div>


            {/* Summary cards */}
            <div className="flex flex-col sm:flex-row gap-4">
                {/* Biggest Expense Card */}
                <div className="flex-1 rounded-lg shadow-lg border bg-primaryBg border-primaryBorder overflow-hidden">
                    <div className="bg-primaryBg p-4 border-b border-primaryBorder flex justify-between items-center">
                        <h3 className="text-primary font-primary truncate">Maior Gasto</h3>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500 flex-shrink-0" />
                    </div>
                    <div className="p-4 bg-white">
                        {biggestExpenseCategory ? (
                            <div className="flex flex-col gap-1">
                                <h4 className="text-lg text-primary truncate">{biggestExpenseCategory.category.name}</h4>
                                <p className="text-red-500 font-semibold whitespace-nowrap">R${formatBrazilianCurrence(biggestExpenseCategory.total.toFixed(2))}</p>
                                <p className="text-xs text-secondaryText mt-1 line-clamp-2">
                                    Esta categoria representa {((biggestExpenseCategory.total / totalExpenses) * 100).toFixed(0)}% do total de suas despesas
                                </p>
                            </div>
                        ) : (
                            <p className="text-secondaryText">Nenhuma despesa cadastrada</p>
                        )}
                    </div>
                </div>

                {/* Total Expenses Card */}
                <div className="flex-1 rounded-lg shadow-lg border border-primaryBorder overflow-hidden">
                    <div className="bg-primaryBg p-4 border-b border-primaryBorder flex justify-between items-center">
                        <h3 className="text-primary font-primary truncate">Despesas Totais</h3>
                        <ChartBarIcon className="h-5 w-5 text-blue-500 flex-shrink-0" />
                    </div>
                    <div className="p-4 bg-white">
                        <div className="flex flex-col gap-1">
                            <p className="text-red-500 text-lg font-semibold whitespace-nowrap">R${formatBrazilianCurrence(totalExpenses.toFixed(2))}</p>
                            <div className="flex items-center gap-2 mt-1 flex-wrap">
                                <ShieldCheckIcon className="h-4 w-4 text-blue-500 flex-shrink-0" />
                                <p className="text-sm text-blue-600 whitespace-nowrap">Reserva recomendada: R${formatBrazilianCurrence(emergencyFund)}</p>
                            </div>
                            <p className="text-xs text-secondaryText mt-1 line-clamp-2 sm:line-clamp-none">
                                Recomenda-se manter uma reserva de emergência equivalente a pelo menos 6x suas despesas
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Transactions list */}
            <div className="flex flex-col w-full gap-3">
                <h2 className="text-primary font-primary">Detalhamento das Despesas</h2>
                <div className="flex flex-col gap-4">
                    {groupedTransactions.map((group, idx) => (
                        <GroupedTransaction
                            onSelectTransaction={(id: string) => {
                                setSelectedTransaction(id);
                                setOpenCreateTransaction(true);
                            }}
                            total={group.total}
                            key={idx}
                            category={group.category}
                            transactions={group.transactions}
                        />
                    ))}
                    {groupedTransactions.length === 0 && (
                        <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow border border-primaryBorder">
                            <p className="text-secondaryText">Você ainda não cadastrou nenhuma despesa fixa.</p>
                            <Button
                                title="+ Nova Despesa"
                                onClick={() => setOpenCreateTransaction(true)}
                                className="bg-blue-600 hover:bg-blue-700 rounded-2xl text-white mt-4"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}