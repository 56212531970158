import { format, getMonth, getYear, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { copyMonthBudget, getMonthsWithBudget } from "../../services/MonthBudgetService";
import { Button } from "../Button";
import Loading from "../Loading";
import SidebarModal from "./SidebarModal";

type MonthBudgetCopyModalProps = {
    open: boolean;
    onClose: () => void;
    currentMonth: string;
}

export default function MonthBudgetCopyModal({ open, onClose, currentMonth }: MonthBudgetCopyModalProps) {
    const currentMonthDate = parse(currentMonth, "yyyy-MM-dd", new Date());
    const currentMonthFormatted = format(currentMonthDate, "MMMM yyyy", { locale: ptBR });
    const currentMonthNum = getMonth(currentMonthDate) + 1;
    const currentYearNum = getYear(currentMonthDate);

    // State for available months
    const [availableMonths, setAvailableMonths] = useState<{ label: string, month: number, year: number }[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<{ label: string, month: number, year: number } | null>(null);
    const [loading, setLoading] = useState(false);
    const [fetchingMonths, setFetchingMonths] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { setNotification } = useAppContext();

    // Fetch months that have budgets
    useEffect(() => {
        if (open) {
            const fetchMonthsWithBudget = async () => {
                try {
                    setFetchingMonths(true);
                    setError(null);
                    setAvailableMonths([]);
                    setSelectedMonth(null);

                    // Get months with budgets from API
                    const response = await getMonthsWithBudget();

                    const budgetMonths = response?.data?.months || [];

                    if (budgetMonths.length === 0) {
                        setError("Não há meses anteriores com orçamentos disponíveis.");
                        return;
                    }

                    // Format the months for display
                    const formattedMonths = budgetMonths.map(m => {
                        const date = new Date(2000, m.month - 1, 1); // Use year 2000 as placeholder
                        date.setFullYear(m.year);

                        return {
                            label: format(date, "MMMM yyyy", { locale: ptBR }),
                            month: m.month,
                            year: m.year
                        };
                    });

                    console.log("Formatted months:", formattedMonths); // Debug

                    if (formattedMonths.length === 0) {
                        setError("Não há meses anteriores com orçamentos disponíveis.");
                        return;
                    }

                    setAvailableMonths(formattedMonths);
                    setSelectedMonth(formattedMonths[0]);
                } catch (err) {
                    console.error("Error fetching months:", err); // Debug
                    setError("Não foi possível carregar os meses com orçamentos.");
                } finally {
                    setFetchingMonths(false);
                }
            };

            fetchMonthsWithBudget();
        }
    }, [open, currentMonth]);

    const handleCopyBudget = async () => {
        if (!selectedMonth) return;

        try {
            setLoading(true);
            await copyMonthBudget({
                month: currentMonthNum,
                year: currentYearNum,
                copy_month: selectedMonth.month,
                copy_year: selectedMonth.year
            });

            setNotification({
                type: 'success',
                message: `Orçamento copiado com sucesso`,
                title: `Orçamento copiado`
            });
            onClose();
        } catch (error: any) {
            // Check if it's a 404 error (no budgets found in source month)
            if (error?.response?.status === 404) {
                setNotification({
                    type: 'error',
                    message: `O mês selecionado não possui orçamentos para copiar.`,
                    title: 'Nenhum orçamento encontrado'
                });
            } else {
                setNotification({
                    type: 'error',
                    message: 'Tente novamente mais tarde.',
                    title: 'Erro ao copiar orçamento'
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <SidebarModal
            open={open}
            onClose={onClose}
            title="Copiar orçamento"
            action={
                <Button
                    disabled={loading || !selectedMonth || fetchingMonths}
                    loading={loading}
                    onClick={handleCopyBudget}
                    className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                    title="Copiar orçamento"
                />
            }
        >
            <div className="flex flex-col gap-4 flex-grow mt-4">
                <div className="text-sm text-gray-700">
                    <p>Esta ação irá copiar todas as categorias e valores do orçamento selecionado para o mês de <span className="font-semibold capitalize">{currentMonthFormatted}</span>.</p>
                    <p className="mt-2">Se houver categorias já configuradas neste mês, elas serão <span className="font-semibold text-red-600">substituídas</span> pelos valores do mês selecionado.</p>
                </div>

                <div className="mt-2">
                    <h4 className="text-xs font-semibold text-gray-700 mb-2">Selecione o mês de origem</h4>

                    {fetchingMonths ? (
                        <div className="flex justify-center p-8">
                            <Loading />
                        </div>
                    ) : error ? (
                        <div className="p-4 border border-gray-300 rounded-md bg-gray-50 text-center">
                            <p className="text-sm text-gray-700">{error}</p>
                            <p className="text-xs text-gray-500 mt-2">Configure orçamentos em meses anteriores para poder usá-los como base para o mês atual.</p>
                        </div>
                    ) : (
                        <div className="border border-gray-300 rounded-md">
                            {availableMonths.map((month) => (
                                <div
                                    key={`${month.month}-${month.year}`}
                                    className={`flex items-center p-3 cursor-pointer border-b border-gray-200 last:border-b-0 ${selectedMonth && selectedMonth.month === month.month && selectedMonth.year === month.year ? 'bg-blue-50' : 'hover:bg-gray-50'}`}
                                    onClick={() => setSelectedMonth(month)}
                                >
                                    <div className="flex items-center mr-3">
                                        <input
                                            type="radio"
                                            name="month-selection"
                                            checked={Boolean(selectedMonth && selectedMonth.month === month.month && selectedMonth.year === month.year)}
                                            onChange={() => setSelectedMonth(month)}
                                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                        />
                                    </div>
                                    <span className="text-sm text-gray-800 capitalize">{month.label}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </SidebarModal>
    );
}