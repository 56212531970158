import { useState } from "react";
import BaseCard from "../components/BaseCard";
import { Button } from "../components/Button";
import FileCard from "../components/FileCard";
import FileCreationModal from "../components/modals/FileCreationModal";
import { GoalCardListSkeletonLoader } from "../components/skeleton/GoalCardSkeleton";
import { useFiles } from "../hooks/useFiles";
import { useUser } from "../hooks/useUsers";

export default function FilesPage() {
    const { data: user } = useUser();
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const { data: files, isLoading, isFetching } = useFiles({});

    return (
        <div className="flex flex-col w-full gap-4 justify-start">
            {/* Header section */}
            <div className="flex flex-col w-full gap-2">
                <h1 className="text-primary font-primary">Materiais Bônus</h1>
            </div>

            {/* Content section */}
            {!isLoading && !isFetching && (
                <div className="flex flex-row flex-wrap w-full gap-6">
                    {files?.results?.map((file) => (
                        <FileCard
                            key={file.id}
                            file={file}
                            onEdit={() => {
                                setOpen(true);
                                setSelectedFile(file.id);
                            }}
                        />
                    ))}

                    {user?.is_admin && (
                        <BaseCard
                            className="max-w-80 min-w-72 border-dashed border-gray-400 py-4 shadow-md hover:shadow-lg transition-shadow"
                            content={
                                <div className="flex px-4 flex-col items-center justify-center gap-1 rounded-t-lg">
                                    <h3 className="font-primary text-sm font-medium text-primaryText">Novo arquivo</h3>
                                    <h3 className="text-sm text-center text-secondaryText mb-3 font-text">Disponibilizar novos materiais</h3>
                                    <Button
                                        onClick={() => {
                                            setOpen(true);
                                            setSelectedFile(null);
                                        }}
                                        title="Cadastrar Material"
                                    />
                                </div>
                            }
                            icon={<></>}
                            title="Cadastrar Material"
                            hideTitle={true}
                        />
                    )}
                </div>
            )}

            {(isLoading || isFetching) && <GoalCardListSkeletonLoader />}

            <FileCreationModal
                fileId={selectedFile}
                open={open}
                onClose={() => setOpen(false)}
            />
        </div>
    );
}