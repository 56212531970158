import { useEffect } from "react";
import queryClient, { QueryKey } from "../hooks/state";
import { useAISummary } from "../hooks/useAI";
import AIReportAvailable from "./alberto/ReportAvailable";
import AIReportGenerating from "./alberto/ReportGenerating";
import AIReportReady from "./alberto/ReportReady";
import AIReportSkeletonLoader from "./alberto/ReportSkeletonLoader";
import AIReportUnavailable from "./alberto/ReportUnavailable";

export default function AISummary() {
    const { data: summary, isLoading } = useAISummary();

    useEffect(() => {
        const interval = setInterval(() => {
            if (summary?.status === 'generating' || summary?.status === 'regenerating') {
                queryClient.invalidateQueries(QueryKey.aiSummary);
            } else {
                clearInterval(interval);
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [summary, queryClient]);

    return (
        <div className="flex flex-col w-full bg-primaryBg border border-secondaryBorder rounded-xl px-2 py-1 shadow-lg overflow-hidden">
            {isLoading && <AIReportSkeletonLoader />}
            {!isLoading && <>
                {summary?.status === "pending_settings" && <AIReportUnavailable />}
                {summary?.allow_generate && summary?.status === "pending_generate" && <AIReportAvailable />}
                {(summary?.status === "generating" || summary?.status === "regenerating") && <AIReportGenerating />}
                {summary?.status === "generated" && <AIReportReady />}
            </>}
        </div>
    )
}