import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { TransactionCategory } from "../models/types";
import { Button } from "./Button";
import { BudgetChart } from "./charts/BudgetChart";

export default function BudgetSummaryPaywall() {
    const { setPaymentModal } = useAppContext();
    const categories: {
        results: TransactionCategory[];
    } = {
        results: [
            {
                "id": "019f7c92-a0ca-4a0a-90b2-0da953da7cc6",
                "name": "Custos Fixos",
                "category_type": "expense",
                "budget_amount": 3500.00
            },
            {
                "id": "af912a2b-66b0-4376-9384-9f54946a47d6",
                "name": "Custos Variáveis",
                "category_type": "expense",
                "budget_amount": 1500.00
            },
            {
                "id": "6905ea8e-e427-484f-ab1e-89dd6361725a",
                "name": "Investimentos",
                "category_type": "investment",
                "budget_amount": 2000.00
            },
            {
                "id": "25b76ef9-f236-45bd-ab88-95a5dbd2f7ec",
                "name": "Metas",
                "category_type": "investment",
                "budget_amount": 1500.00
            },
            {
                "id": "bc5448ab-c257-40c2-9365-367c23b19ee9",
                "name": "Lazer",
                "category_type": "expense",
                "budget_amount": 1000.00
            },
            {
                "id": "bc5448ab-c257-40c2-9365-367c23b19ee9",
                "name": "Estudos",
                "category_type": "expense",
                "budget_amount": 500.00
            }
        ]
    }
    const navigate = useNavigate();
    return (
        <div className="flex flex-col w-full flex-grow justify-center items-center bg-primaryBg border border-secondaryBorder rounded-xl overflow-hidden shadow-lg relative">
            {/* Chart content with reduced opacity */}
            <div className="bg-white w-full p-4 opacity-20 pointer-events-none">
                {categories?.results?.length === 0 && (
                    <div className="flex py-5 flex-col items-center justify-center gap-1 h-64">
                        <h3 className="font-semibold text-sm">Não configurado</h3>
                        <h3 className="text-sm text-gray-600">Configure seu orçamento mensal</h3>
                        <h4 className="mt-2 text-sm cursor-pointer hover:bg-indigo-800 text-white py-1 px-4 rounded-md bg-indigo-700">
                            Configurar
                        </h4>
                    </div>
                )}
                {categories?.results && categories?.results?.length > 0 && (
                    <div className="h-full">
                        <BudgetChart categories={categories.results} />
                    </div>
                )}
            </div>

            {/* Custom centered paywall button that overlays the chart */}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                    <LockClosedIcon className="h-16 w-16 text-gray-500 mb-2" />
                    <Button
                        title="Exclusivo para assinantes"
                        onClick={() => setPaymentModal(true)}
                    />
                </div>
            </div>
        </div>
    )
}