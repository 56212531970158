import { 
  CurrencyDollarIcon, 
  PresentationChartLineIcon, 
  ClockIcon 
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

type SimulatorOption = {
  id: string;
  name: string;
  description: string;
  icon: React.FC<React.ComponentProps<'svg'>>;
  path: string;
  comingSoon?: boolean;
};

export default function SimuladoresPage() {
  const navigate = useNavigate();

  const simulators: SimulatorOption[] = [
    {
      id: 'independencia-financeira',
      name: 'Independência Financeira',
      description: 'Simule seu caminho para a independência financeira com base em seus aportes mensais, patrimônio atual e taxa de juros esperada.',
      icon: CurrencyDollarIcon,
      path: '/simuladores/independencia-financeira',
    },
    {
      id: 'juros-compostos',
      name: 'Juros Compostos',
      description: 'Calcule o impacto dos juros compostos nos seus investimentos ao longo do tempo.',
      icon: PresentationChartLineIcon,
      path: '/simuladores/juros-compostos',
    },
    {
      id: 'aposentadoria',
      name: 'Aposentadoria',
      description: 'Planeje sua aposentadoria calculando quanto você precisa poupar mensalmente.',
      icon: ClockIcon,
      path: '/simuladores/aposentadoria',
      comingSoon: true, // Mantenha como "em breve" por enquanto
    },
    // Aqui você pode adicionar mais simuladores no futuro
  ];

  return (
    <div className="flex flex-col gap-6">
      <div className="text-left">
        <h1 className="text-xl font-semibold text-gray-900 mb-1">Simuladores</h1>
        <p className="text-sm text-gray-600">
          Ferramentas para ajudar você a tomar decisões financeiras inteligentes com base em simulações de diversos cenários.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {simulators.map((simulator) => (
          <div 
            key={simulator.id} 
            className={`rounded-lg shadow-md overflow-hidden transition-all duration-200 hover:shadow-lg ${simulator.comingSoon ? 'opacity-75' : 'cursor-pointer'}`}
            onClick={() => !simulator.comingSoon && navigate(simulator.path)}
          >
            <div className="bg-blue-50 p-5 h-full flex flex-col">
              <div className="flex justify-between items-start mb-4">
                <div className="p-2 rounded-full bg-blue-100 text-blue-600">
                  <simulator.icon className="h-6 w-6" />
                </div>
                {simulator.comingSoon && (
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                    Em breve
                  </span>
                )}
              </div>
              
              <h3 className="text-lg font-semibold text-gray-900 mb-2">{simulator.name}</h3>
              
              <p className="text-gray-600 text-sm mb-4 flex-grow">
                {simulator.description}
              </p>
              
              <div className="mt-auto">
                {!simulator.comingSoon ? (
                  <button
                    className="w-full py-1.5 px-3 bg-blue-500 rounded-md font-medium text-white hover:bg-blue-600 transition-colors text-sm"
                    onClick={() => navigate(simulator.path)}
                  >
                    Acessar
                  </button>
                ) : (
                  <button
                    className="w-full py-1.5 px-3 bg-gray-200 rounded-md font-medium text-gray-500 cursor-not-allowed text-sm"
                    disabled
                  >
                    Em desenvolvimento
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}