import { useQuery } from "react-query";
import { MonthBudgetFetchParams, MonthBudgetSummaryFetchParams } from '../models/types';
import { getMonthBudget, getMonthBudgets, getMonthBudgetSummary } from './../services/MonthBudgetService';
import { QueryKey } from "./state";

export const useMonthBudgets = (params: MonthBudgetFetchParams) => {
    return useQuery([QueryKey.monthBudget, params], async () => {
        const { data } = await getMonthBudgets(params);
        return data;
    });
}

export const useMonthBudget = (budgetId: string) => {
    return useQuery([QueryKey.monthBudget, budgetId], async () => {
        if (budgetId === "") return null;
        const { data } = await getMonthBudget(budgetId);
        return data;
    });
}

export const useMonthBudgetSummary = (params: MonthBudgetSummaryFetchParams) => {
    return useQuery([QueryKey.monthBudgetSummary, params], async () => {
        const { data } = await getMonthBudgetSummary(params);
        return data;
    });
}