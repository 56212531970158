import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { addTransaction, removeTransaction, useGoal, useGoalTransactions } from "../../hooks/useGoals";
import { GoalTransaction } from "../../models/types";
import { createGoal, deleteGoal, updateGoal } from "../../services/GoalService";
import { formatToBrazilianCurrency, handleMoneyChange } from "../../utils/handleMoneyChange";
import { Button } from "../Button";
import ConfirmationModal from "./ConfirmationModal";
import SidebarModal from "./SidebarModal";

type GoalCreationModalProps = {
    open: boolean;
    onClose: () => void;
    goalId: string | null;
}

// Helper to generate a unique ID for transactions
const generateId = () => Math.random().toString(36).substring(2) + Date.now().toString(36);

// Helper to get transactions from localStorage
const getTransactionsFromStorage = (goalId: string): GoalTransaction[] => {
    try {
        const stored = localStorage.getItem(`goal_transactions_${goalId}`);
        if (stored) {
            return JSON.parse(stored);
        }
    } catch (error) {
        console.error("Error parsing transactions from localStorage:", error);
    }
    return [];
};

// Helper to save transactions to localStorage
const saveTransactionsToStorage = (goalId: string, transactions: GoalTransaction[]) => {
    try {
        localStorage.setItem(`goal_transactions_${goalId}`, JSON.stringify(transactions));
    } catch (error) {
        console.error("Error saving transactions to localStorage:", error);
    }
};

export default function GoalCreationModal({ open, onClose, goalId }: GoalCreationModalProps) {
    // Basic goal information
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [goalAmount, setGoalAmount] = useState("");
    const [goalAmountError, setGoalAmountError] = useState("");
    const [goalDate, setGoalDate] = useState("");
    const [goalDateError, setGoalDateError] = useState("");

    // Transaction form states
    const [transactionAmount, setTransactionAmount] = useState("");
    const [transactionAmountError, setTransactionAmountError] = useState("");
    const [transactionDescription, setTransactionDescription] = useState("");
    const [transactionDescriptionError, setTransactionDescriptionError] = useState("");
    const [isAddingTransaction, setIsAddingTransaction] = useState(false);
    const [transactionType, setTransactionType] = useState<'add' | 'remove'>('add');
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [deleteTransactionId, setDeleteTransactionId] = useState<string | null>(null);

    // Other states
    const [activeTab, setActiveTab] = useState<'details' | 'transactions'>('details');
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useAppContext();

    // Fetch goal data
    const { data: goal, isLoading } = useGoal(goalId);

    // Load goal transactions from the API
    const { data: transactionData } = useGoalTransactions(goalId);

    useEffect(() => {
        if (goal) {
            setName(goal.name);
            setAmount(`${goal.amount}`.replace(/[.]/g, ","));
            setGoalAmount(`${goal.goal_amount}`.replace(/[.]/g, ","));
            setGoalDate(goal.goal_date);
        } else {
            setName("");
            setAmount("");
            setGoalAmount("");
            setGoalDate("");
        }
        resetTransactionForm();
    }, [goal, open])

    const handleDeleteGoal = async () => {
        try {
            if (goalId) {
                await deleteGoal(goalId);
                setNotification({
                    type: 'success',
                    message: 'Meta excluida com sucesso',
                    title: 'Meta excluida'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhuma meta selecionada',
                    title: 'Erro ao excluir meta'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir meta'
            })
        }
    }

    // Reset transaction form fields
    const resetTransactionForm = () => {
        setTransactionAmount("");
        setTransactionAmountError("");
        setTransactionDescription("");
        setTransactionDescriptionError("");
        setTransactionType('add');
        setIsAddingTransaction(false);
    }

    // Update goal amount from all transactions
    const calculateTotalAmount = (transactions: GoalTransaction[]): number => {
        return transactions.reduce((total, transaction) => total + transaction.amount, 0);
    }

    // Handle adding a new transaction
    const handleAddTransaction = () => {
        try {
            setTransactionLoading(true);

            // Validate transaction inputs
            if (!transactionAmount || transactionAmount === "0" || transactionAmount === "0,00" || transactionAmount === "-") {
                setTransactionAmountError("Informe um valor válido");
                setTransactionLoading(false);
                return;
            }

            if (!transactionDescription.trim()) {
                setTransactionDescriptionError("Adicione uma descrição");
                setTransactionLoading(false);
                return;
            }

            if (!goalId) {
                setNotification({
                    type: 'error',
                    message: "Salve a meta antes de adicionar transações",
                    title: "Erro"
                });
                setTransactionLoading(false);
                return;
            }

            // Calculate the amount based on transaction type
            let numericAmount = Math.abs(Number(transactionAmount.replace(/[,]/g, ".")));
            if (transactionType === 'remove') {
                numericAmount = -numericAmount; // Make negative for removals
            }

            // Call backend API to add transaction
            addTransaction(goalId, numericAmount, transactionDescription)
                .then(() => {

                    // Update goal amount in UI to match backend
                    if (goal) {
                        setAmount(((goal.amount + numericAmount).toString()).replace(/[.]/g, ","));
                    }

                    setNotification({
                        type: 'success',
                        message: transactionType === 'add' ? "Valor depositado na meta" : "Valor sacado da meta",
                        title: "Sucesso"
                    });

                    resetTransactionForm();
                })
                .catch(error => {
                    setNotification({
                        type: 'error',
                        message: transactionType === 'add' ? "Erro ao depositar valor na meta" : "Erro ao sacar valor da meta",
                        title: "Erro"
                    });
                })
                .finally(() => {
                    setTransactionLoading(false);
                });
        } catch (error) {
            setNotification({
                type: 'error',
                message: "Erro ao processar transação",
                title: "Erro"
            });
            setTransactionLoading(false);
        }
    }

    // Handle removing a transaction
    const handleRemoveTransaction = (transactionId: string) => {
        if (!goalId) return;

        try {
            setTransactionLoading(true);

            // Find transaction to remove and get its amount
            const transactionToRemove = transactionData?.find(t => t.id === transactionId);
            if (!transactionToRemove) {
                throw new Error('Transaction not found');
            }

            // Call backend API to remove transaction
            removeTransaction(goalId, transactionId)
                .then(() => {

                    // Update goal amount in UI
                    if (goal) {
                        setAmount(((goal.amount - transactionToRemove.amount).toString()).replace(/[.]/g, ","));
                    }

                    setNotification({
                        type: 'success',
                        message: "Transação removida com sucesso",
                        title: "Sucesso"
                    });

                    // Close confirmation dialog
                    setDeleteTransactionId(null);
                })
                .catch(error => {
                    setNotification({
                        type: 'error',
                        message: "Erro ao remover transação",
                        title: "Erro"
                    });
                })
                .finally(() => {
                    setTransactionLoading(false);
                });
        } catch (error) {
            setNotification({
                type: 'error',
                message: "Erro ao remover transação",
                title: "Erro"
            });
            setTransactionLoading(false);
            setDeleteTransactionId(null);
        }
    }

    // Handle the main goal submission
    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (name === "") {
                setLoading(false);
                return setNameError("Informe um nome para a meta");
            }
            if (goalAmount === "") {
                setLoading(false);
                return setGoalAmountError("Informe o valor da meta");
            }
            if (goalDate === "") {
                setLoading(false);
                return setGoalDateError("Informe a data da meta");
            }

            // If creating a new goal with initial amount
            if (!goalId) {
                const response = await createGoal({
                    name,
                    amount: 0, // Always start with zero and add a transaction for the initial amount
                    goal_amount: goalAmount === "" ? 0 : Number(goalAmount.replace(/[,]/g, ".")),
                    goal_date: goalDate,
                });

                // If we have an initial amount and the goal was created successfully,
                // create a transaction for the initial amount via API
                if (response && response.id && amount && Number(amount.replace(/[,]/g, ".")) > 0) {
                    const newGoalId = response.id;
                    const initialAmount = Number(amount.replace(/[,]/g, "."));

                    // Call the API to add the initial transaction
                    await addTransaction(newGoalId, initialAmount, "Valor inicial");
                }
            } else {
                // Updating existing goal
                await updateGoal(goalId, {
                    name,
                    amount: amount === "" ? 0 : Number(amount.replace(/[,]/g, ".")),
                    goal_amount: goalAmount === "" ? 0 : Number(goalAmount.replace(/[,]/g, ".")),
                    goal_date: goalDate,
                });
            }

            onClose();
            setNotification({
                type: 'success',
                message: `Meta ${goalId ? "editada" : "adicionada"} com sucesso`,
                title: `Meta`
            });
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${goalId ? "editar" : "adicionar"} a meta, tente novamente`,
                title: `Meta`
            });
        } finally {
            setLoading(false);
        }
    }

    // Function to render the transactions tab
    const renderTransactionsTab = () => {
        return (
            <div className="flex flex-col gap-4 mt-2">
                <div className="flex justify-between items-center">
                    <h3 className="text-sm font-primary text-primaryText">Histórico de Transações</h3>
                    {!isAddingTransaction && (
                        <div className="flex gap-2">
                            <button
                                onClick={() => { 
                                    setIsAddingTransaction(true); 
                                    setTransactionAmount("");
                                    setTransactionType('add');
                                }}
                                className="text-xs px-2 py-1 rounded-md text-blue-600 border border-blue-600 hover:bg-blue-50 font-text flex items-center gap-1"
                            >
                                <PlusCircleIcon className="h-3 w-3" />
                                Depositar
                            </button>
                            <button
                                onClick={() => { 
                                    setIsAddingTransaction(true); 
                                    setTransactionAmount("");
                                    setTransactionType('remove');
                                }}
                                className="text-xs px-2 py-1 rounded-md text-red-600 border border-red-600 hover:bg-red-50 font-text flex items-center gap-1"
                            >
                                <PlusCircleIcon className="h-3 w-3" />
                                Sacar
                            </button>
                        </div>
                    )}
                </div>

                {/* Transaction Form */}
                {isAddingTransaction && (
                    <div className="bg-gray-50 p-3 rounded-lg border border-gray-200">
                        <h4 className="text-sm font-primary text-primaryText mb-2">
                            {transactionType === 'remove' ? 'Sacar Valor' : 'Depositar Valor'}
                        </h4>

                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <label className="text-xs font-text text-secondaryText mb-1">Valor</label>
                                <div className="flex items-center">
                                    <span className="text-sm font-text text-primaryText mr-1">R$</span>
                                    <input
                                        type="text"
                                        value={transactionAmount}
                                        onChange={(e) => {
                                            handleMoneyChange(e, setTransactionAmount);
                                            setTransactionAmountError("");
                                        }}
                                        className={`py-1.5 px-2 text-sm font-text rounded-lg border flex-grow ${transactionAmountError ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="0,00"
                                    />
                                </div>
                                {transactionAmountError && (
                                    <p className="text-xs font-text text-red-500 mt-1">{transactionAmountError}</p>
                                )}
                            </div>

                            <div className="flex flex-col">
                                <label className="text-xs font-text text-secondaryText mb-1">Descrição</label>
                                <input
                                    type="text"
                                    value={transactionDescription}
                                    onChange={(e) => {
                                        setTransactionDescription(e.target.value);
                                        setTransactionDescriptionError("");
                                    }}
                                    className={`py-1.5 px-2 text-sm font-text rounded-lg border ${transactionDescriptionError ? 'border-red-500' : 'border-gray-300'}`}
                                    placeholder="Ex: Depósito, 13º salário, etc."
                                />
                                {transactionDescriptionError && (
                                    <p className="text-xs font-text text-red-500 mt-1">{transactionDescriptionError}</p>
                                )}
                            </div>

                            <div className="flex gap-2 mt-1">
                                <button
                                    onClick={resetTransactionForm}
                                    className="text-xs px-3 py-1.5 rounded-md text-gray-600 border border-gray-300 hover:bg-gray-100 font-text flex-1"
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleAddTransaction}
                                    disabled={transactionLoading}
                                    className={`text-xs px-3 py-1.5 rounded-md text-white font-text flex-1 flex justify-center items-center ${transactionType === 'remove' ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'}`}
                                >
                                    {transactionLoading ? (
                                        <div className="h-3 w-3 rounded-full border-2 border-white border-t-transparent animate-spin" />
                                    ) : (
                                        transactionType === 'remove' ? "Sacar" : "Depositar"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Transaction List */}
                <div className="flex flex-col gap-1">
                    {transactionData && transactionData.length > 0 ? (
                        <div className="max-h-80 overflow-y-auto pr-1">
                            {transactionData.map((transaction: GoalTransaction) => (
                                <div
                                    key={transaction.id}
                                    className="flex items-center justify-between py-2 border-b border-gray-100"
                                >
                                    <div className="flex flex-col">
                                        <span className="text-sm font-text text-primaryText">{transaction.description}</span>
                                        <div className="flex items-center gap-1">
                                            <span className={`text-xs font-text ${transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                                {transaction.amount >= 0 ? '+' : '-'} R$ {formatToBrazilianCurrency(Math.abs(transaction.amount).toString())}
                                            </span>
                                            <span className="text-[10px] text-gray-400">
                                                • {format(parseISO(transaction.created_at), 'dd/MM/yyyy')}
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => setDeleteTransactionId(transaction.id)}
                                        className="p-1 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50"
                                    >
                                        <TrashIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="py-8 flex flex-col items-center text-center">
                            <p className="text-sm font-text text-gray-400">Nenhuma transação registrada</p>
                            <p className="text-xs font-text text-gray-400 mt-1">
                                Adicione valores à sua meta para acompanhar seu progresso
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    // Function to render the details tab
    const renderDetailsTab = () => {
        return (
            <div className="flex flex-col gap-4 mt-2">
                <div className="flex flex-col gap-1">
                    <h4 className="text-sm font-primary text-primaryText">Nome</h4>
                    <input
                        type="text"
                        name="meta"
                        id="meta-name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            setNameError("");
                        }}
                        className={`py-1.5 px-2 text-sm font-text rounded-lg border ${nameError ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Nome da meta"
                    />
                    {nameError && <p className="text-xs font-text text-red-500">{nameError}</p>}
                </div>

                <div className="flex flex-col gap-1">
                    <h4 className="text-sm font-primary text-primaryText">Valor da Meta</h4>
                    <div className="flex items-center">
                        <span className="text-sm font-text text-primaryText mr-1">R$</span>
                        <input
                            type="text"
                            name="goalAmount"
                            id="goal-amount"
                            value={goalAmount}
                            onChange={(e) => {
                                handleMoneyChange(e, setGoalAmount)
                                setGoalAmountError("");
                            }}
                            className={`py-1.5 px-2 text-sm font-text rounded-lg border flex-grow ${goalAmountError ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="0,00"
                        />
                    </div>
                    {goalAmountError && <p className="text-xs font-text text-red-500">{goalAmountError}</p>}
                    <p className="text-xs font-text text-secondaryText">Informe o valor total da meta</p>
                </div>

                {!goalId && (
                    <div className="flex flex-col gap-1">
                        <h4 className="text-sm font-primary text-primaryText">Valor Inicial</h4>
                        <div className="flex items-center">
                            <span className="text-sm font-text text-primaryText mr-1">R$</span>
                            <input
                                type="text"
                                name="amount"
                                id="amount"
                                value={amount}
                                onChange={(e) => {
                                    handleMoneyChange(e, setAmount)
                                    setAmountError("");
                                }}
                                className={`py-1.5 px-2 text-sm font-text rounded-lg border flex-grow ${amountError ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder="0,00"
                            />
                        </div>
                        {amountError && <p className="text-xs font-text text-red-500">{amountError}</p>}
                        <p className="text-xs font-text text-secondaryText">Informe o valor inicial se já possuir</p>
                    </div>
                )}

                <div className="flex flex-col gap-1">
                    <h4 className="text-sm font-primary text-primaryText">Data Final</h4>
                    <input
                        type="date"
                        name="goalDate"
                        id="goal-date"
                        value={goalDate}
                        onChange={(e) => {
                            setGoalDate(e.target.value)
                            setGoalDateError("");
                        }}
                        className={`py-1.5 px-2 text-sm font-text rounded-lg border ${goalDateError ? 'border-red-500' : 'border-gray-300'}`}
                    />
                    {goalDateError && <p className="text-xs font-text text-red-500">{goalDateError}</p>}
                    <p className="text-xs font-text text-secondaryText">Informe a data que deseja atingir a meta</p>
                </div>

                {/* Value summary - only for existing goals */}
                {goalId && (
                    <div className="bg-blue-50 p-3 rounded-lg border border-blue-100 mt-2">
                        <h4 className="text-sm font-primary text-blue-800 mb-1">Resumo</h4>
                        <div className="flex justify-between items-center">
                            <span className="text-xs font-text text-blue-600">Valor acumulado:</span>
                            <span className="text-sm font-text text-blue-800 font-medium">
                                R$ {formatToBrazilianCurrency(goal?.amount?.toString() || "0")}
                            </span>
                        </div>
                        <div className="flex justify-between items-center mt-1">
                            <span className="text-xs font-text text-blue-600">Valor da meta:</span>
                            <span className="text-sm font-text text-blue-800 font-medium">
                                R$ {formatToBrazilianCurrency(goal?.goal_amount?.toString() || "0")}
                            </span>
                        </div>
                        <div className="flex justify-between items-center mt-1">
                            <span className="text-xs font-text text-blue-600">Faltam:</span>
                            <span className="text-sm font-text text-blue-800 font-medium">
                                R$ {formatToBrazilianCurrency(((goal?.goal_amount ?? 0) - (goal?.amount ?? 0))?.toString() || "0")}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <SidebarModal
            title={`${goalId ? "Editar" : "Criar"} Meta`}
            onDelete={goalId ? () => setOpenConfirmation(true) : undefined}
            action={
                <Button
                    disabled={loading}
                    loading={loading}
                    onClick={() => handleSubmit()}
                    className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                    title={goalId ? "Salvar Alterações" : "Criar Meta"}
                />
            }
            open={open}
            onClose={onClose}
        >
            {/* Tabs - only show for existing goals */}
            {goalId && (
                <div className="flex border-b border-gray-200 mb-2">
                    <button
                        onClick={() => setActiveTab('details')}
                        className={`px-4 py-2 text-sm font-text ${activeTab === 'details'
                            ? 'text-blue-600 border-b-2 border-blue-600 font-medium'
                            : 'text-gray-600 hover:text-gray-800'
                            }`}
                    >
                        Detalhes
                    </button>
                    <button
                        onClick={() => setActiveTab('transactions')}
                        className={`px-4 py-2 text-sm font-text ${activeTab === 'transactions'
                            ? 'text-blue-600 border-b-2 border-blue-600 font-medium'
                            : 'text-gray-600 hover:text-gray-800'
                            }`}
                    >
                        Transações
                    </button>
                </div>
            )}

            {/* Tab content */}
            {goalId && activeTab === 'transactions' ? renderTransactionsTab() : renderDetailsTab()}

            {/* Confirmation modals */}
            <ConfirmationModal
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                onConfirm={() => handleDeleteGoal()}
                onCancel={() => setOpenConfirmation(false)}
                title="Excluir Meta"
                message={`Deseja realmente excluir a meta ${goal?.name}`}
            />

            <ConfirmationModal
                open={!!deleteTransactionId}
                onClose={() => setDeleteTransactionId(null)}
                onConfirm={() => deleteTransactionId && handleRemoveTransaction(deleteTransactionId)}
                onCancel={() => setDeleteTransactionId(null)}
                title="Excluir Transação"
                message="Deseja realmente excluir esta transação? Esta ação não pode ser desfeita."
            />
        </SidebarModal>
    )
}
